import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Tag, Menu, Dropdown, Popconfirm, Space, Button, Avatar, Card, notification } from 'antd'
import { load, remove } from '../actions'
import './index.scss'
import { serviceStatusTag } from '../../shared/components/serviceStatusTag'
import { ImagePresignedUrl } from '@renderer'
import Toolbar from '@toolbar'
import access from '@access'
import moment from 'moment'
import Papa from 'papaparse'
import { useRouteMatch } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import enumMedicalHistoryType from '../../../../utilities/enumMedicalHistoryType'

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [search, setSearch] = useState('')
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        type: 0,
        sort: 'desc',
        search: '',
    })
    const prevPagination = useRef(pagination)
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.admin.medicalHistories)

    useEffect(() => {
        if (prevPagination.current !== pagination) dispatch(load(pagination))
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        setTableData([...getData.data])
    }, [getData])

    function handleDelete(id) {
        dispatch(remove(id)).then(res => {
            if (res.type === 'medicalHistories/removeFulfilled') {
                notification.success({ message: 'Deleted', description: 'You have successfully deleted a data.' });
                dispatch(load(pagination))
            }
        })
    }

    useEffect(() => {
        if (search) {
            const delayDebounceFn = setTimeout(() => {
                setPagination(prevState => ({ ...prevState, search }));
            }, 1300)
            return () => clearTimeout(delayDebounceFn)
        } else dispatch(load({ ...pagination, search: '' }))
    }, [search])

    function handleView(data) {
        props.history.push(`/medical-histories/view/${data._id}`)
    }

    function typeLabel(item) {
        switch (item) {
            case 1:
                return 'ANNUAL'
            case 2:
                return 'MEDICAL'
            case 3:
                return 'OTHERS'
            default:
                return 'All'
        }
    }

    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.user && data.user.firstName} ${data.user && data.user.lastName}`,
                "Description": data.description,
                "Type": typeLabel(data.type),
                "Date": moment(data.date).format('LLLL'),
                "Remarks": data.remarks
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `medical_histories_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `medical_histories_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={44} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            responsive: ['lg', 'md', 'sm'],
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            responsive: ['lg', 'md', 'sm'],
            render: (item, data) => {
                switch (item) {
                    case 1:
                        return <Tag color="#03045e">ANNUAL</Tag>
                    case 2:
                        return <Tag color="#0077b6">MEDICAL</Tag>
                    case 3:
                        return <Tag color="#caf0f8">OTHERS</Tag>
                    default:
                        break;
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item) => {
                return (<span>{serviceStatusTag(item)}</span>)
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <Space>
                        <Button type='primary' size='small' onClick={() => handleView(data)}  >
                            View
                        </Button>
                        {(access.check('medicalHistories', 'delete') && data.status === 1) &&
                            <Button type='danger' size='small'>
                                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                    Delete
                                </Popconfirm>
                            </Button>
                        }
                    </Space>
                )
            }
        },

    ];
    return (
        <Layout className='page' >
            <Card bordered={false}>
                <Row gutter={32}>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <Toolbar
                            title='Medical Histories'
                            handleAdd={() => props.history.push(`${path}/create`)}
                            removeAdd={!access.check('medicalHistories', 'add')}
                            handleDownload={() => handleDownload()}
                            handleSearch={(search) => setSearch(search)}
                            // handleSort={(sort) => setPagination({ ...pagination, sort })}
                            handleStatus={(status) => setPagination({ ...pagination, status })}
                            customFilter={[
                                {
                                    component: <Dropdown key={1} className='dropdown-filter' overlay={(
                                        <Menu items={[
                                            { label: 'All', key: 0, onClick: () => { setPagination({ ...pagination, type: 0 }) } },
                                            ...Object.keys(enumMedicalHistoryType).map(function (key, index) {
                                                return { label: key, key: `medical_type_${enumMedicalHistoryType[key]}`, onClick: () => { setPagination({ ...pagination, type: enumMedicalHistoryType[key] }) } }
                                            })
                                        ]} />
                                    )} placement="bottom">
                                        <Button>
                                            <span className='fade'>Type </span><span className='label'>{typeLabel(pagination.type)}</span><b><DownOutlined /></b>
                                        </Button>
                                    </Dropdown>
                                },
                            ]}
                            statusArray={[
                                { id: 0, label: 'All' },
                                { id: 1, label: 'For Approval' },
                                { id: 2, label: 'Approved' },
                                { id: 5, label: 'Cancelled' },
                                { id: 6, label: 'Rejected' },
                            ]}
                        />
                        <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                        <Row style={{ marginTop: 30, width: '100%' }}>
                            <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Layout>
    )
}

export default List