import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Divider, notification, DatePicker, TimePicker, Select, Alert, Card } from 'antd'
import { CustomDropzone } from '../../../../utilities/customDropzone'
import { upload } from '../../../../utilities/filesController'
import { load as loadEmployees, loadById, update, create } from '../actions';
import moment from 'moment'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { load as loadPositions } from '../../positions/actions'
import session from '@session'
import access from '../../../../utilities/access'
import { load as loadProjects } from '../../projects/actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
import './index.scss'

const { Option } = Select
const FormEmployee = (props) => {
    const [form] = Form.useForm()
    const [userDetails, setUserDetails] = useState({})
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [loading, setLoading] = useState(false)
    const [fileImage, setFileImage] = useState(undefined)
    const [fileImageObj, setFileImageObj] = useState(undefined)
    const [isPositionChanged, setIsPositionChanged] = useState(false)
    const positions = useSelector((state) => state.admin.positions)
    const projects = useSelector((state) => state.admin.projects)
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch()
    const { getData, getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.employees)
    const userId = id

    useEffect(() => {
        if (path === '/employees/create') setPageAction(PAGE_CREATE)
        else if (path === '/employees/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    dateHired: res.payload && moment(res.payload.dateHired),
                    dateResigned: res.payload && moment(res.payload.dateResigned),
                    birthday: res.payload && moment(res.payload.birthday),
                    shiftIn: moment(moment(new Date()).format('DD/MM/YYYY') + ' ' + res.payload.shiftIn, 'DD/MM/YYYY HH:mm A'),
                    shiftOut: moment(moment(new Date()).format('DD/MM/YYYY') + ' ' + res.payload.shiftOut, 'DD/MM/YYYY HH:mm A'),
                })
                setUserDetails(res.payload)
            })


            if (userId) {
                dispatch(loadProjects({ page: 1, limit: 100, }))
                dispatch(loadPositions({ page: 1, limit: 100, }))
                dispatch(loadEmployees({ page: 1, limit: 50, }))
            }
        }
    }, [])


    function handleSubmit(value) {
        setLoading(true)

        if (pageAction === PAGE_CREATE) {
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                const submitData = {
                    ...value,
                    postById: session.user().id,
                    positionDate: isPositionChanged ? new Date : undefined
                }
                if (fileImageObj) submitData.image = fileImageObj
                if (isAllResultTrue) {
                    delete value.religion
                    dispatch(create({
                        ...submitData,
                        shiftIn: moment(submitData.shiftIn).format("hh:mm A"),
                        shiftOut: moment(submitData.shiftOut).format("hh:mm A")
                    })).then(res => {
                        if (res.type === 'employees/createFulfilled') {
                            setLoading(false)
                            notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                            goBack()
                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                        // description: err,
                    });
                    setLoading(false)
                }
            })
        }

        if (pageAction === PAGE_VIEW) {
            const userId = id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                const submitData = { id: userId, ...value, postById: session.user().id, positionDate: isPositionChanged ? new Date : undefined }
                if (fileImageObj) submitData.image = fileImageObj
                if (isAllResultTrue) {
                    delete value.religion
                    dispatch(update({
                        ...submitData,
                        shiftIn: moment(submitData.shiftIn).format("hh:mm A"),
                        shiftOut: moment(submitData.shiftOut).format("hh:mm A")
                    })).then(res => {
                        if (res.type === 'employees/updateFulfilled') {
                            setLoading(false)
                            notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                            goBack()

                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                        // description: err,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (fileImage) {
                upload(fileImage, fileImage.name, 'images').then(data => {
                    return resolve(true)
                }).catch(err => {
                    return notification.error({
                        message: `Failed to upload image`,
                        description: err,
                    });
                })
            } else {
                return resolve(true)
            }
        })
    }

    function getFileImage(e) {
        const obj = {
            name: e.name,
            url: `images/${e.name}`
        }
        setFileImage(e)
        setFileImageObj(obj)
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }
    const isAccessDisabled = (!access.check('employeeMasterfile', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='Employees'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`employee-${props.selectedId}`}
                form={form}

                onFinish={(e) => handleSubmit(e)}
            // onFinishFailed={onFinishFailed}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={24} xs={24} lg={24} sm={24}>
                        <div className='user-details' >
                            <div className='user-view-left'>
                                <div className='photo' >
                                    <CustomDropzone
                                        disabled={isAccessDisabled}
                                        fileName='profile'
                                        height={200}
                                        width={200}
                                        getFile={file => { getFileImage(file) }}
                                        value={userDetails.image && userDetails.image[0] && userDetails.image[0].url}
                                    />
                                    <p className='emp-number'>#{userDetails.employeeNumber}</p>
                                </div>

                                <div className='details'>
                                    <p className='name'>{`${userDetails.firstName || ''} ${userDetails.lastName || ''}`}</p>
                                    <p className='birthdate'>Hired Date:  {userDetails.dateHired ? `${moment(userDetails.dateHired).format('LL')}` : ''}</p>
                                    <p className='item'>Position: {`${userDetails.position && userDetails.position.title || ''}`}</p>
                                    <p className='item'>Current Project: {`${userDetails.project && userDetails.project.title || ''}`}</p>
                                    <p className='item'>Vacation Leave: {`${userDetails && userDetails.vacationLeaveDays || ''}`}</p>
                                    <p className='item'>Sick Leave: {`${userDetails && userDetails.sickLeaveDays || ''}`}</p>
                                    <div className='ratings'>
                                        <p>JOB LEVEL</p>
                                        <div className='ratings-flex'>
                                            <span className='job-level-label'>{userDetails.position && userDetails.position.jobLevel && userDetails.position.jobLevel.toFixed(1)}</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Divider />
                {
                    // Show only when in view or if the employee already have a position.
                    ((pageAction === PAGE_VIEW) && (userDetails.position && userDetails.position.jobLevel && userDetails.position)) &&
                    <Fragment>
                        <Alert
                            style={{ marginBottom: 12 }}
                            message="Position (Read-Only)"
                            description={
                                <span>
                                    Position, Project and Employment Status fields are read only, If you want to change the position of this employee you may go to <a href='/personal-movements'>Personnel Movements</a>
                                </span>
                            }
                            type="warning"
                            closable
                        />
                        <Row gutter={12}>
                            <Col md={8} lg={8} sm={24} xs={24}>
                                <Form.Item
                                    label="Position"
                                    name="positionId"
                                    rules={[{ required: true, message: 'Please input position!' }]}
                                >
                                    <Select disabled onChange={() => setIsPositionChanged(true)}>
                                        {
                                            positions.getData.data.map((item, index) => {
                                                return (
                                                    <Option value={item._id} key={index}>{item.title}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col md={8} lg={8} sm={24} xs={24}>
                                <Form.Item
                                    label="Project"
                                    name="projectId"
                                    rules={[{ required: true, message: 'Please input project!' }]}
                                >
                                    <Select disabled onChange={() => setIsPositionChanged(true)}>
                                        {
                                            projects.getData.data.map((item, index) => {
                                                return (
                                                    <Option value={item._id} key={index}>{item.title}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col md={8} lg={8} sm={24} xs={24}>
                                <Form.Item
                                    label="Employment Status"
                                    name="employmentStatus"
                                    rules={[{ required: true, message: 'Please input employment status!' }]}
                                >
                                    <Select disabled={userDetails.employmentStatus}>
                                        <Option value={1}>Probationary</Option>
                                        <Option value={2}>Regular</Option>
                                        <Option value={3}>Resigned</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Divider />
                        </Row>
                    </Fragment>
                }

                <Row gutter={12}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Manager"
                            name="managerId"
                            rules={[{ required: true, message: 'Please input manager!' }]}
                        >
                            <Select disabled={isAccessDisabled} onChange={() => setIsPositionChanged(true)}>
                                {
                                    getData.data.map((item, index) => {
                                        return (
                                            <Option value={item._id} key={index}>{item.firstName} {item.lastName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Divider />
                </Row>

                <h5 style={{ fontSize: 24 }}>Personal Information</h5>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input last name!' }]}
                        >
                            <Input
                                disabled={isAccessDisabled}
                                value={userDetails.lastName} onChange={(e) => {
                                    setUserDetails({ ...userDetails, lastName: e.target.value })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input first name!' }]}
                        >
                            <Input disabled={isAccessDisabled} value={userDetails.firstName} onChange={(e) => {
                                setUserDetails({ ...userDetails, firstName: e.target.value })
                            }} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Middle Name"
                            name="middleName"
                            rules={[{ required: true, message: 'Please input middle name!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Employee Number"
                            name="employeeNumber"
                            rules={[{ required: true, message: 'Please input employee number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[{ required: true, message: 'Please input gender!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                <Select.Option value={1}>Male</Select.Option>
                                <Select.Option value={2}>Female</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Marital Status"
                            name="maritalStatus"
                            rules={[{ required: true, message: 'Please input maritial status!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Birthday"
                            name="birthday"
                            rules={[{ required: true, message: 'Please input birthday!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                                value={userDetails.birthday} onChange={(e) => {
                                    setUserDetails({ ...userDetails, birthday: e })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: 'Please input address!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Contact"
                            name="contact"
                            rules={[{ required: true, message: 'Please input contact!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="SSS Number"
                            name="sssNumber"
                            rules={[{ required: true, message: 'Please input sss number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="TIN"
                            name="tin"
                            rules={[{ required: true, message: 'Please input TIN!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Philhealth Number"
                            name="philHealthNumber"
                            rules={[{ required: true, message: 'Please input philhealth number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Pag-Ibig Number"
                            name="pagIbigNumber"
                            rules={[{ required: true, message: 'Please input pag ibig number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Pag-Ibig Prem"
                            name="pagIbigPrem"
                            rules={[{ required: true, message: 'Please input Pag Ibig Prem!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Tax Status"
                            name="taxStatus"
                            rules={[{ required: true, message: 'Please input tax status!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input email!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Account Number"
                            name="accountNumber"
                            rules={[{ required: true, message: 'Please input account number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        {
                            // Show only if the employee does not have a position yet.
                            ((pageAction === PAGE_CREATE) || !(userDetails.position && userDetails.position.jobLevel)) &&
                            <Form.Item
                                label="Position"
                                name="positionId"
                                rules={[{ required: true, message: 'Please input position!' }]}
                            >
                                <Select disabled={isAccessDisabled} onChange={({ }, e) => {
                                    const jobLevel = e.data.jobLevel
                                    setIsPositionChanged(true)
                                    setUserDetails({
                                        ...userDetails, ...{
                                            position: {
                                                jobLevel
                                            }
                                        }
                                    })
                                }}>
                                    {
                                        positions.getData.data.map((item, index) => {
                                            return (
                                                <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }

                        {
                            // Show only if the employee does not have a project yet.
                            ((pageAction === PAGE_CREATE) || !(userDetails.position && userDetails.position.jobLevel)) &&
                            <Form.Item
                                label="Project"
                                name="projectId"
                                rules={[{ required: true, message: 'Please input project!' }]}
                            >
                                <Select disabled={isAccessDisabled} >
                                    {
                                        projects.getData.data.map((item, index) => {
                                            return (
                                                <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }

                        {
                            // Show only if the employee does not have a Employment status.
                            ((pageAction === PAGE_CREATE) || !(userDetails && userDetails.employmentStatus)) &&
                            <Form.Item
                                label="Employment Status"
                                name="employmentStatus"
                                rules={[{ required: true, message: 'Please input employment status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>Probationary</Option>
                                    <Option value={2}>Regular</Option>
                                    <Option value={3}>Resigned</Option>
                                </Select>
                            </Form.Item>
                        }

                        <Form.Item
                            label="Citizenship"
                            name="citizenship"
                            rules={[{ message: 'Please input citizen ship!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Religion"
                            name="religion"
                            rules={[{ message: 'Please input religion!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Date Hired"
                            name="dateHired"
                            rules={[{ required: true, message: 'Please input date hired!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Date Resigned"
                            name="dateResigned"
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Shift In"
                            name="shiftIn"
                            rules={[{ required: true, message: 'Please input shift in!' }]}
                        >
                            <TimePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Shift Out"
                            name="shiftOut"
                            rules={[{ required: true, message: 'Please input shift out!' }]}
                        >
                            <TimePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    (pageAction === PAGE_CREATE) &&
                    <Fragment>
                        <Divider />
                        <h5 style={{ fontSize: 24 }}>Security</h5>
                        <Row gutter={16}>
                            <Col md={8} lg={8} sm={24} xs={24} >
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input password!' }]}
                                >
                                    <Input disabled={isAccessDisabled} placeholder='Password' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Fragment>
                }

                <Divider />
                <h5 style={{ fontSize: 24 }}>Company Code</h5>
                <Row gutter={16}>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Center Code"
                            name="centerCode"
                            rules={[{ required: true, message: 'Please input center code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Section Code"
                            name="sectionCode"
                            rules={[{ required: true, message: 'Please input section code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Property Code"
                            name="propertyCode"
                            rules={[{ required: true, message: 'Please input property code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Division Code"
                            name="divisionCode"
                            rules={[{ required: true, message: 'Please input division code!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />
                <h5 style={{ fontSize: 24 }}>Total Leave Days</h5>
                <Row gutter={16}>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Vacation Leave Days"
                            name="vacationLeaveDays"
                            rules={[{ required: true, message: 'Please input vacation leave!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Sick Leave Days"
                            name="sickLeaveDays"
                            rules={[{ required: true, message: 'Please input sick leave!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Emergency Leave Days"
                            name="emergencyLeaveDays"
                            rules={[{ required: true, message: 'Please input emergency leave!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Others Leave Days"
                            name="othersLeaveDays"
                            rules={[{ required: true, message: 'Please input others leave!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />
                <h5 style={{ fontSize: 24 }}>Salary</h5>
                <Row gutter={16}>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Rate Per Month"
                            name="ratePerMonth"
                            rules={[{ required: true, message: 'Please input rate per month!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Rate Per Hour"
                            name="ratePerHour"
                            rules={[{ required: true, message: 'Please input rate per hour!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Working Days Per Month"
                            name="workingDaysPerMonth"
                            rules={[{ required: true, message: 'Please input working days per month!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />
                <h5 style={{ fontSize: 24 }}>Allowance</h5>
                <Row gutter={16}>
                    <Col md={8} lg={8} sm={24} xs={24} >
                        <Form.Item
                            label="Allowance amount"
                            name="allowance"
                            rules={[{ required: true, message: 'Please input allowance!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    ((access.check('employeeMasterfile', 'add') && (pageAction === PAGE_CREATE)) || (access.check('employeeMasterfile', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit" loading={createLoading || updateLoading || getByIdLoading || loading} >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Card>
    )
}

export default FormEmployee