import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Button, Layout, Form, DatePicker, Modal, notification } from 'antd'
import { update, create } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import session from '@session'
import './index.scss'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

const FormDependent = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { updateLoading } = useSelector(state => state.client.dependents)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            birthDate: props.selectedRow && moment(props.selectedRow.birthDate) || new Date(),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = { ...value, }

        if (props.actionType === ADD) {
            const userId = session.user().id
            dispatch(create({ userId, ...submitData })).then(res => {
                if (res.type === 'dependents/createFulfilled') {
                    props.setActionType(NONE)
                    setLoading(false)
                    form.resetFields()
                    notification.success({
                        message: `Added`,
                        description: 'You have successfully added information.',
                    });
                }
            }).finally(() => {
                setLoading(false)
            })
        }

        if (props.actionType === VIEW) {
            const dependentId = props.selectedRow._id
            dispatch(update({ id: dependentId, ...submitData })).then(res => {
                if (res.type === 'dependents/updateFulfilled') {
                    setLoading(false)
                    form.resetFields()
                    notification.success({
                        message: `Added`,
                        description: 'You have successfully updated information.',
                    });
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    return (
        <Modal forceRender title={props.selectedRow && props.selectedRow.company || 'Add Dependent'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`dependent`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: 'Please input first name!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: 'Please input last name!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Contact Number"
                                name="contact"
                                rules={[{ required: true, message: 'Please input contact number!' }]}
                            >
                                <Input type='number' />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Relationship"
                                name="relationship"
                                rules={[{ required: true, message: 'Please input relationship!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Birth Date"
                                name="birthDate"
                                rules={[{ required: true, message: 'Please input birth date!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={updateLoading || loading}   >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

export default FormDependent