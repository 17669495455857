import { createSlice } from "@reduxjs/toolkit";

const announcements = createSlice({
  name: 'announcements',
  initialState: {
    getLoading: false,
    getSuccess: false,
    getRejected: false,
    getData: {
      data: []
    },
    getByIdLoading: false,
    getByIdSuccess: false,
    getByIdRejected: false,
    getByIdData: {},
    updateLoading: false,
    updateSuccess: false,
    updateRejected: false,
    createLoading: false,
    createSuccess: false,
    createRejected: false,
    removeLoading: false,
    removeSuccess: false,
    removeRejected: false,
    getAllLoading: false,
    getAllSuccess: false,
    getAllRejected: false,
    getAllData: [],
    announcementInventoriesCountLoading: false,
    announcementInventoriesCountSuccess: false,
    announcementInventoriesCountRejected: false,
    announcementInventoriesCountData: {
      sold: 0, open: 0, reserved: 0, overall: 0
    }
  },
  reducers: {
    getAnnouncements(state, action) {
      return {
        ...state,
        getLoading: true,
        getSuccess: false,
        getRejected: false
      }
    },
    getAnnouncementsFulfilled(state, action) {
      return {
        ...state,
        getLoading: false,
        getSuccess: true,
        getData: action.payload
      }
    },
    getAnnouncementsRejected(state, action) {
      return {
        ...state,
        getLoading: false,
        getRejected: true
      }
    },
    getAnnouncementsAll(state, action) {
      return {
        ...state,
        getAllLoading: true,
        getAllSuccess: false,
        getAllRejected: false
      }
    },
    getAnnouncementsAllFulfilled(state, action) {
      return {
        ...state,
        getAllLoading: false,
        getAllSuccess: true,
        getAllData: action.payload
      }
    },
    getAnnouncementsAllRejected(state, action) {
      return {
        ...state,
        getAllLoading: false,
        getAllRejected: true
      }
    },

    getAnnouncementInventoriesCount(state, action) {
      return {
        ...state,
        announcementInventoriesCountLoading: true,
        announcementInventoriesCountSuccess: false,
        announcementInventoriesCountRejected: false
      }
    },
    getAnnouncementInventoriesCountFulfilled(state, action) {
      return {
        ...state,
        announcementInventoriesCountLoading: false,
        announcementInventoriesCountSuccess: true,
        announcementInventoriesCountData: action.payload
      }
    },
    getAnnouncementInventoriesCountRejected(state, action) {
      return {
        ...state,
        announcementInventoriesCountLoading: false,
        announcementInventoriesCountRejected: true
      }
    },

    getAnnouncementById(state, action) {
      return {
        ...state,
        getByIdLoading: true,
        getByIdSuccess: false,
        getByIdRejected: false
      }
    },
    getAnnouncementByIdFulfilled(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdSuccess: true,
        getByIdData: action.payload
      }
    },
    getAnnouncementByIdRejected(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdRejected: true
      }
    },
    createAnnouncement(state, action) {
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createRejected: false
      }
    },
    createAnnouncementFulfilled(state, action) {
      return {
        ...state,
        createLoading: false,
        createSuccess: true,
        createData: action.payload
      }
    },
    createAnnouncementRejected(state, action) {
      return {
        ...state,
        createLoading: false,
        createRejected: true
      }
    },
    updateAnnouncement(state, action) {
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        updateRejected: false
      }
    },
    updateAnnouncementFulfilled(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
        updateData: action.payload
      }
    },
    updateAnnouncementRejected(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateRejected: true
      }
    },
    removeAnnouncement(state, action) {
      return {
        ...state,
        removeLoading: true,
        removeSuccess: false,
        removeRejected: false
      }
    },
    removeAnnouncementFulfilled(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeSuccess: true,
        removeData: action.payload
      }
    },
    removeAnnouncementRejected(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeRejected: true
      }
    }
  }
});

export const actions = announcements.actions;

export default announcements.reducer;