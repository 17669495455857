import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Card, Form, notification } from 'antd'
import { loadById, create, update } from '../actions'
import access from '@access'
import './index.scss'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'

const FormProject = (props) => {
    const [form] = Form.useForm()
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.projects)
    const userId = getByIdData.user && getByIdData.user._id

    useEffect(() => {
        if (path === '/projects/create') setPageAction(PAGE_CREATE)
        else if (path === '/projects/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({ ...res.payload, })
            })
        }
    }, [])

    function handleSubmit(value) {
        const submitData = { ...value, }
        if (pageAction === PAGE_CREATE) {
            dispatch(create({ ...submitData })).then(res => {
                if (res.type === 'projects/createFulfilled') {
                    notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                    goBack()
                }
            })
        }
        else if (pageAction === PAGE_VIEW) {
            const projectId = id
            dispatch(update({ id: projectId, ...submitData })).then(res => {
                if (res.type === 'projects/updateFulfilled') {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                    goBack()
                }
            })
        }
        else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    };

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }

    const isAccessDisabled = (!access.check('projects', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='Projects'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`project-${props.selectedId}`}
                form={form}

                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    ((access.check('projects', 'add') && (pageAction === PAGE_CREATE)) || (access.check('projects', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading} disabled={getByIdLoading || createLoading || updateLoading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Card>
    )
}

export default FormProject