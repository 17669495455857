import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Form, Select, notification, DatePicker, Card, Checkbox } from 'antd'
import { loadById, create, update } from '../actions'
import { load as loadEmployees } from '../../employee/actions';
import moment from 'moment'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '@renderer'
import { upload } from '../../../../utilities/filesController'
import session from '@session'
import _ from 'lodash'
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
import './index.scss'

const { Option } = Select
const FormDocument = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [search, setSearch] = useState('')
    const [resetVerifier, setResetVerifier] = useState(false)
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const employees = useSelector(state => state.admin.employees)
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.documents)
    const userId = getByIdData.user && getByIdData.user._id

    useEffect(() => {
        if (path === '/documents/create') setPageAction(PAGE_CREATE)
        else if (path === '/documents/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    expirationDate: res.payload && moment(res.payload.expirationDate),
                })
                res.payload && res.payload.images && setImages(res.payload.images)
            })

            if (userId) dispatch(loadEmployees({ page: 1, limit: 50, id: userId, }))
        }
    }, [])


    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
        }, 1000);
        return () => clearTimeout(timerId);
    }, [search]);

    function handleSubmit(value) {

        const submitData = {
            ...value,
            yearFrom: moment(value.yearFromTo[0]).format('YYYY'),
            yearTo: moment(value.yearFromTo[1]).format('YYYY')
        }

        if (pageAction === PAGE_CREATE) {
            dispatch(create({ ...submitData })).then(res => {
                if (res.type === 'educations/createFulfilled') {
                    notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                    goBack()
                }
            })
        } else if (pageAction === PAGE_VIEW) {
            dispatch(update({ id, ...submitData })).then(res => {
                if (res.type === 'educations/updateFulfilled') {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                    goBack()
                }
            })
        } else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {
                    upload(item, item.name, item.uploadDir).then(data => { return resolve(true) }).catch(err => {
                        return notification.error({ message: `Failed to upload image`, description: err, });
                    })
                })
            } else { return resolve(true) }
        })
    }

    function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            postById: session.user().id,
            ...value,
        }

        if (pageAction === PAGE_CREATE) {
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(create({ images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                        goBack()
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })
        }

        if (pageAction === PAGE_VIEW) {
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    const documentId = id
                    dispatch(update({ id: documentId, images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                        goBack()
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })
        }
    }


    const isAccessDisabled = (!access.check('documents', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='Documents'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`document`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (pageAction === PAGE_CREATE) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select disabled={isAccessDisabled} placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={employees.getLoading}>
                                    {
                                        employees.getData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="ID Number"
                            name="idNumber"
                            rules={[{ required: true, message: 'Please input ID number!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please input type!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>


                        <Form.Item
                            name="isOriginalCopySubmitted"
                            valuePropName="checked"
                        >
                            <Checkbox disabled={isAccessDisabled}>Is Submitted Original Copy?</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Expiration Date"
                            name="expirationDate"
                            rules={[{ required: true, message: 'Please input expiration date!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        {
                            (pageAction === PAGE_VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('documents', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('documents', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('documents', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <CustomDropzoneMultiple
                            disabled={isAccessDisabled}
                            fileName='documents'
                            uploadDir='documents'
                            height={200}
                            width={200}
                            getFile={file => { getFileImage(file) }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <div className='d-flex'>
                            {
                                images.map((item, index) => {
                                    return (
                                        <div key={index} >
                                            <ImagePresignedUrl src={item.url} style={{ width: 200, height: 150 }} />
                                            {
                                                ((access.check('documents', 'add') && (pageAction === PAGE_CREATE)) || (access.check('documents', 'edit') && (pageAction === PAGE_VIEW))) &&
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
                {
                    ((access.check('documents', 'add') && (pageAction === PAGE_CREATE)) || (access.check('documents', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading || loading} disabled={getByIdLoading || createLoading || updateLoading || loading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }

            </Form>
        </Card>
    )
}

export default FormDocument