import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { io } from "socket.io-client";
import { getToken } from '../utilities/token';
import { Spin } from 'antd'
import { useSelector } from 'react-redux'
import ClientComponent from './client'
import AdminComponent from './admin'
import LoginComponent from './login/components/index'
import { userType } from '@enums'
import session from '@session'
const { CLIENT, ADMIN } = userType
const SERVER = process.env.REACT_APP_WEBSOCKET_URL;

const App = (props) => {
  const { loggedIn, loggedData } = useSelector(state => state.login)

  const [userType, setUserType] = useState()
  const [isLoading, setIsLoading] = useState()
  // Check session token in initial load
  useEffect(() => {
    setIsLoading(true)
    const type = session.user() && session.user().type
    setUserType(type)
    setIsLoading(false)
  }, [])

  // Check state when logging in
  useEffect(() => {
    if (loggedIn) {
      // const type = props.loggedData && props.loggedData.user.type
      // setUserType(type)
      window.location.reload();
    }
  }, [loggedIn])

  useEffect(() => {
    // Initialized Socket IO
    // this.socket = io(SERVER, { query: { token: getToken() } });
    // console.log("WSS SERVER", SERVER)
  }, [])

  const DisplayComponent = () => {
    switch (userType) {
      case CLIENT:
        return <ClientComponent {...props}
        //  socket={this.socket} 
        />
      case ADMIN:
        return <AdminComponent {...props}
        //  socket={this.socket}
        />
    }
  }
  return isLoading ? <Spin spinning /> : userType ? <DisplayComponent /> : <LoginComponent {...props} />
}

export default App
