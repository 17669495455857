import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, notification } from 'antd'
import { loadById, update, create } from '../actions'
import { load as loadEmployees } from '../../employee/actions';
import moment from 'moment'
import { load as loadProjects } from '../../projects/actions'
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
import './index.scss'

const { Option } = Select
const FormAccountability = (props) => {
    const [form] = Form.useForm()
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [search, setSearch] = useState('')
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const employees = useSelector(state => state.admin.employees)
    const projects = useSelector(state => state.admin.projects)
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.accountabilities)
    const userId = getByIdData.user && getByIdData.user._id

    useEffect(() => {
        if (path === '/accountabilities/create') setPageAction(PAGE_CREATE)
        else if (path === '/accountabilities/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    date: res.payload && moment(res.payload.date),
                })
            })

            if (userId) dispatch(loadEmployees({ page: 1, limit: 50, id: userId, }))
        }
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
            dispatch(loadProjects({ page: 1, limit: 50, search: search, }))
        }, 1000);
        return () => clearTimeout(timerId);
    }, [search]);

    function handleSubmit(value) {

        const submitData = { ...value, }

        if (pageAction === PAGE_CREATE) {
            dispatch(create({ ...submitData })).then(res => {
                if (res.type === 'accountabilities/createFulfilled') {
                    notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                    goBack()
                }
            })
        } else if (pageAction === PAGE_VIEW) {
            dispatch(update({ id, ...submitData })).then(res => {
                if (res.type === 'accountabilities/updateFulfilled') {
                    notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                    goBack()
                }
            })
        } else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }

    const isAccessDisabled = (!access.check('accountabilities', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Layout style={{ backgroundColor: 'white' }}>
            <Toolbar
                title='Accountabilities'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`accountability`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (pageAction === PAGE_CREATE) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={employees.getLoading}>
                                    {
                                        employees.getData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Date"
                            name="date"
                            rules={[{ required: true, message: 'Please input date!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Quantity"
                            name="quantity"
                            rules={[{ required: true, message: 'Please input quantity!' }]}
                        >
                            <Input type={'number'} disabled={isAccessDisabled} />
                        </Form.Item>

                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Value"
                            name="value"
                            rules={[{ required: true, message: 'Please input value!' }]}
                        >
                            <Input type={'number'} disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Projects"
                            name="projectId"
                            rules={[{ required: true, message: 'Please input project id!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                {
                                    projects.getData.data.map((data, index) => {
                                        return (
                                            <Option value={data._id} key={index}>{data.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        {
                            (pageAction === PAGE_VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled}>
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('accountabilities', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('accountabilities', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('accountabilities', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                {
                    ((access.check('accountabilities', 'add') && (pageAction === PAGE_CREATE)) || (access.check('accountabilities', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading} disabled={getByIdLoading || createLoading || updateLoading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Layout>
    )
}

export default FormAccountability