import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Form, Select, DatePicker, Checkbox, Alert, notification, Card, Space, Divider } from 'antd'
import { loadById, update, create } from '../actions'
import './index.scss'
import moment from 'moment'
import { load as loadProjects } from '../../projects/actions'
import { load as loadEmployees, loadById as loadEmployeeById } from '../../employee/actions'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '@renderer'
import { upload } from '../../../../utilities/filesController'
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
import FormType from './modals/type'
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select

const FormLeave = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [pageAction, setPageAction] = useState(PAGE_CREATE)

    const [search, setSearch] = useState('')
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])
    const [modalTypeShow, setModalTypeShow] = useState(false)
    const [userDetails, setUserDetails] = useState({})
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch()
    const employees = useSelector(state => state.admin.employees)
    const projects = useSelector(state => state.admin.projects)
    const { getByIdData, getByIdLoading, createLoading, updateLoading, getTypeData } = useSelector(state => state.admin.leaves)
    const userId = getByIdData.userId

    useEffect(() => {
        if (path === '/leaves/create') setPageAction(PAGE_CREATE)
        else if (path === '/leaves/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    dateFrom: res.payload && moment(res.payload.dateFrom),
                    dateTo: res.payload && moment(res.payload.dateTo),
                })
                res.payload && res.payload.images && setImages(res.payload.images)

                dispatch(loadEmployeeById(res.payload.userId))
            })

            if (userId) dispatch(loadEmployees({ page: 1, limit: 50, id: userId, }))

        }

        dispatch(loadProjects({ page: 1, limit: 100, }))
    }, [])


    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {
                    upload(item, item.name, item.uploadDir).then(data => { return resolve(true) }).catch(err => {
                        return notification.error({ message: `Failed to upload image`, description: err, });
                    })
                })
            } else { return resolve(true) }
        })
    }


    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
        }, 1000);
        return () => clearTimeout(timerId);
    }, [search]);


    function handleSubmit(value) {
        setLoading(true)

        const submitData = { ...value, }

        if (pageAction === PAGE_CREATE) {
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(create({ images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        if (res.type === 'leaves/createFulfilled') {
                            notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                            goBack()
                            form.resetFields()
                        }
                        setLoading(false)
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })
        }

        if (pageAction === PAGE_VIEW) {
            const leaveId = id
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(update({ id: leaveId, images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        if (res.type === 'leaves/updateFulfilled') {
                            notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                            goBack()
                            setLoading(false)
                        }
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }


    const isAccessDisabled = (!access.check('leaveApplications', 'edit') && (pageAction === PAGE_VIEW))
    // do not allowed to edit  'Date from' and 'Date To' when already approved because its been deducted already to the selected user.
    const isDeducted = (getByIdData && getByIdData.isDeducted)

    const { vacationLeaveDays, sickLeaveDays, othersLeaveDays, emergencyLeaveDays } = employees.getByIdData
    return (
        <Card bordered={false}>
            <Toolbar
                title='Leaves'
                handleBack={() => props.history.goBack()}
            />
            {
                (pageAction === PAGE_VIEW) ?
                    <>
                        <div className='preview'>
                            <ImagePresignedUrl src={employees.getByIdData && employees.getByIdData.image && employees.getByIdData.image[0].url} style={{ width: 64, height: 64, borderRadius: 32 }} objectFit='cover' preview={false} />
                            <h1>{employees.getByIdData.firstName} {employees.getByIdData.lastName}</h1>
                        </div>
                        <Divider />
                    </> : null
            }
            {
                isDeducted ?
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24} style={{ marginBottom: 12 }}  >
                            <Alert
                                message={'Date From, Date To And Type'}
                                description={`Not allowed to edit 'Date from', 'Date To' and 'Type' when already approved because it has been deducted to the selected user`}
                                type={"info"}
                                showIcon
                                closable
                            />
                        </Col>
                    </Row> : null
            }
            <Form
                name={`leave-${props.selectedId}`}
                form={form}

                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (pageAction === PAGE_CREATE) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={employees.getLoading}>
                                    {
                                        employees.getData.data.map((data) => {
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Reason For Taking Leave"
                            name="reason"
                            rules={[{ required: true, message: 'Please input reason!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Date From"
                            name="dateFrom"
                            rules={[{ required: true, message: 'Please input date from!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled || isDeducted}
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Date To"
                            name="dateTo"
                            rules={[{ required: true, message: 'Please input date to!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled || isDeducted}
                                className='primary-input'
                                // format="YYYY-MM-DD HH:mm:ss"
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="isWithPay"
                            valuePropName="checked"
                        >
                            <Checkbox disabled={isAccessDisabled} >Leave with Pay?</Checkbox>
                        </Form.Item>

                        <Form.Item
                            name="isOriginalCopySubmitted"
                            valuePropName="checked"
                        >
                            <Checkbox disabled={isAccessDisabled} >Is Submitted Original Copy?</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Row gutter={8} justify='center'>
                            <Col md={24} lg={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Type"
                                    name="typeId"
                                    rules={[{ required: true, message: 'Please input type!' }]}
                                >
                                    <Select disabled={isAccessDisabled || isDeducted}>
                                        {
                                            getTypeData.data.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item._id}>{item.title}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col md={2} lg={2} sm={24} xs={24}  >
                                <div className='type-options'>
                                    <Button disabled={isAccessDisabled} className='btn-circle-type' onClick={() => setModalTypeShow(true)} shape="circle" icon={<PlusOutlined />} />
                                </div>
                            </Col> */}
                        </Row>

                        <Form.Item
                            label="Projects"
                            name="projectId"
                            rules={[{ required: true, message: 'Please input project id!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                {
                                    projects.getData.data.map((data, index) => {
                                        return (
                                            <Option value={data._id} key={index}>{data.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[{ required: true, message: 'Please input status!' }]}
                        >
                            <Select disabled={isAccessDisabled}>
                                <Option value={1}>FOR APPROVAL</Option>
                                {
                                    access.check('leaveApplications', 'approve') &&
                                    <Option value={2}>APPROVED</Option>
                                }
                                {
                                    access.check('leaveApplications', 'cancel') &&
                                    <Option value={5}>CANCELLED</Option>
                                }
                                {
                                    access.check('leaveApplications', 'reject') &&
                                    <Option value={6}>REJECTED</Option>
                                }
                            </Select>
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <CustomDropzoneMultiple
                            disabled={isAccessDisabled}
                            fileName='leaves'
                            uploadDir='leaves'
                            height={200}
                            width={200}
                            getFile={file => { getFileImage(file) }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <div className='d-flex'>
                            {
                                images.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <ImagePresignedUrl src={item.url} style={{ width: 200, height: 150 }} />
                                            {
                                                ((access.check('leaveApplications', 'add') && (pageAction === PAGE_CREATE)) || (access.check('leaveApplications', 'edit') && (pageAction === PAGE_VIEW))) &&
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
                {
                    (pageAction === PAGE_VIEW) &&
                    <Card className='mb4' title='Available Leaves'>
                        <label>Vacation Leave Days: <b style={{ color: !vacationLeaveDays && 'red' }}>{vacationLeaveDays || 0} Day/s</b></label><br />
                        <label>Sick Leave Days: <b style={{ color: !sickLeaveDays && 'red' }}>{sickLeaveDays || 0} Day/s</b></label><br />
                        <label>Emergency Leave Days: <b style={{ color: !emergencyLeaveDays && 'red' }}>{emergencyLeaveDays || 0} Day/s</b></label><br />
                        <label>Others Leave Days: <b style={{ color: !othersLeaveDays && 'red' }}>{othersLeaveDays || 0} Day/s</b></label><br />
                    </Card>
                }
                {
                    ((access.check('leaveApplications', 'add') && (pageAction === PAGE_CREATE)) || (access.check('leaveApplications', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading || loading} disabled={getByIdLoading || createLoading || updateLoading || loading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
            <FormType visible={modalTypeShow} setModalTypeShow={setModalTypeShow} />
        </Card>
    )
}

export default FormLeave