
import { createSlice } from "@reduxjs/toolkit";

const allowances = createSlice({
    name: 'allowances',
    initialState: {
        getLoading: false,
        getSuccess: false,
        getRejected: false,
        getData: {
            data: []
        },
        getByIdLoading: false,
        getByIdSuccess: false,
        getByIdRejected: false,
        getByIdData: {},
        updateLoading: false,
        updateSuccess: false,
        updateRejected: false,
        createLoading: false,
        createSuccess: false,
        createRejected: false,
        removeLoading: false,
        removeSuccess: false,
        removeRejected: false,
        getAllLoading: false,
        getAllSuccess: false,
        getAllRejected: false,
        getAllData: [],
        getHistoryTypesLoading: true,
        getHistoryTypesSuccess: false,
        getHistoryTypesRejected: false,
        getHistoryTypesData: {
            data: []
        }
    },
    reducers: {
        get(state, action) {
            return {
                ...state,
                getLoading: true,
                getSuccess: false,
                getRejected: false
            }
        },
        getFulfilled(state, action) {
            return {
                ...state,
                getLoading: false,
                getSuccess: true,
                getData: action.payload
            }
        },
        getRejected(state, action) {
            return {
                ...state,
                getLoading: false,
                getRejected: true
            }
        },
        getAll(state, action) {
            return {
                ...state,
                getAllLoading: true,
                getAllSuccess: false,
                getAllRejected: false
            }
        },
        getAllFulfilled(state, action) {
            return {
                ...state,
                getAllLoading: false,
                getAllSuccess: true,
                getAllData: action.payload
            }
        },
        getAllRejected(state, action) {
            return {
                ...state,
                getAllLoading: false,
                getAllRejected: true
            }
        },
        getInventoriesCount(state, action) {
            return {
                ...state,
                allowanceInventoriesCountLoading: true,
                allowanceInventoriesCountSuccess: false,
                allowanceInventoriesCountRejected: false
            }
        },
        getInventoriesCountFulfilled(state, action) {
            return {
                ...state,
                allowanceInventoriesCountLoading: false,
                allowanceInventoriesCountSuccess: true,
                allowanceInventoriesCountData: action.payload
            }
        },
        getInventoriesCountRejected(state, action) {
            return {
                ...state,
                allowanceInventoriesCountLoading: false,
                allowanceInventoriesCountRejected: true
            }
        },

        getById(state, action) {
            return {
                ...state,
                getByIdLoading: true,
                getByIdSuccess: false,
                getByIdRejected: false
            }
        },
        getByIdFulfilled(state, action) {
            return {
                ...state,
                getByIdLoading: false,
                getByIdSuccess: true,
                getByIdData: action.payload
            }
        },
        getByIdRejected(state, action) {
            return {
                ...state,
                getByIdLoading: false,
                getByIdRejected: true
            }
        },
        create(state, action) {
            return {
                ...state,
                createLoading: true,
                createSuccess: false,
                createRejected: false
            }
        },
        createFulfilled(state, action) {
            return {
                ...state,
                createLoading: false,
                createSuccess: true,
                createData: action.payload
            }
        },
        createRejected(state, action) {
            return {
                ...state,
                createLoading: false,
                createRejected: true
            }
        },
        update(state, action) {
            return {
                ...state,
                updateLoading: true,
                updateSuccess: false,
                updateRejected: false
            }
        },
        updateFulfilled(state, action) {
            return {
                ...state,
                updateLoading: false,
                updateSuccess: true,
                updateData: action.payload
            }
        },
        updateRejected(state, action) {
            return {
                ...state,
                updateLoading: false,
                updateRejected: true
            }
        },
        remove(state, action) {
            return {
                ...state,
                removeLoading: true,
                removeSuccess: false,
                removeRejected: false
            }
        },
        removeFulfilled(state, action) {
            return {
                ...state,
                removeLoading: false,
                removeSuccess: true,
                removeData: action.payload
            }
        },
        removeRejected(state, action) {
            return {
                ...state,
                removeLoading: false,
                removeRejected: true
            }
        },
        getHistoryTypes(state, action) {
            return {
                ...state,
                getHistoryTypesLoading: true,
                getHistoryTypesSuccess: false,
                getHistoryTypesRejected: false
            }
        },
        getHistoryTypesFulfilled(state, action) {
            return {
                ...state,
                getHistoryTypesLoading: false,
                getHistoryTypesSuccess: true,
                getHistoryTypesData: action.payload
            }
        },
        getHistoryTypesRejected(state, action) {
            return {
                ...state,
                getHistoryTypesLoading: false,
                getHistoryTypesRejected: true
            }
        },
        getSupplierTin(state, action) {
            return {
                ...state,
                getSupplierTinLoading: true,
                getSupplierTinSuccess: false,
                getSupplierTinRejected: false
            }
        },
        getSupplierTinFulfilled(state, action) {
            return {
                ...state,
                getSupplierTinLoading: false,
                getSupplierTinSuccess: true,
                getSupplierTinData: action.payload
            }
        },
        getSupplierTinRejected(state, action) {
            return {
                ...state,
                getSupplierTinLoading: false,
                getSupplierTinRejected: true
            }
        },
    }
});

export const actions = allowances.actions;

export default allowances.reducer;