import React, { useEffect, useState } from 'react'
import { Card, Layout, Pagination, Row, Col, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { load } from '../actions'
import StatusIconDisplay from '@status-icon'
import FormComponent from './form'
import { NONE } from '@page-actions'
import moment from 'moment'
import enumPersonalMovementType from '../../../../utilities/enumPersonalMovementType'

import Toolbar from '@toolbar'
import './index.scss'

const PersonalMovementsComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})
    const [modalShow, setModalShow] = useState(false)
    const dispatch = useDispatch();
    const { getData, getLoading, createSuccess, updateSuccess, removeSuccess } = useSelector(state => state.client.personalMovements)

    useEffect(() => {
        dispatch(load(pagination))
    }, [pagination])

    useEffect(() => {
        setModalShow(false)
        dispatch(load(pagination))
    }, [updateSuccess, createSuccess, removeSuccess])

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    return (
        <Layout className='personalMovements-page'>
            <Card bordered={false}>
                <Toolbar
                    title='Personal Movements'
                    handleSearch={(search) => setPagination({ ...pagination, search })}
                />
                <Table columns={[
                    {
                        title: 'Type',
                        dataIndex: 'type',
                        key: 'type',
                        render: (item, data) => {
                            return <span>{getKeyByValue(enumPersonalMovementType, data.type)} <StatusIconDisplay status={data.status} /></span>
                        }
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        key: 'code',
                        responsive: ['lg', 'md', 'sm'],
                        render: (item, data) => {
                            return <div >
                                <p>Center Code: {data.centerCode}</p>
                                <p>Property Code: {data.propertyCode}</p>
                                <p>Division Code: {data.divisionCode}</p>
                            </div>
                        }
                    },
                    {
                        title: 'Position',
                        dataIndex: 'position',
                        key: 'position'
                    },
                    {
                        title: 'Job Level',
                        dataIndex: 'jobLevel',
                        key: 'jobLevel',
                        responsive: ['lg', 'md', 'sm'],
                    },
                    {
                        title: 'Effectivity Date',
                        dataIndex: 'effectivityDate',
                        key: 'effectivityDate',
                        render: (item, data) => {
                            return <span>{moment(data.effectivityDate).format('LL')}</span>
                        }
                    }
                ]} dataSource={getData.data} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                <Row style={{ marginTop: 30, width: '100%' }}>
                    <Col md={24} lg={24} sm={24} xs={24} align="right" >
                        <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                    </Col>
                </Row>
            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

export default PersonalMovementsComponent
