import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadReports = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch(actions.getReports())
    return Axios.get(`${API_URL}/dashboard-reports?${query}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getReportsFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getReportsRejected(error))
      })
  }
}

