import Axios from 'axios'
import config from '../../../config'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL
export const submitLogin = (data) => {
  return dispatch => {
    dispatch(actions.postLoginData())
    return Axios.post(`${API_URL}/login`, { ...data })
      .then(function (response) {
        console.log("RESPONSE", response)
        if (response.data.token) {
          localStorage.setItem('AMS_SESSION_TOKEN', response.data.token)
          return dispatch(actions.postLoginDataFulfilled(response.data))
        }
      }).catch(function (error) {
        return dispatch(actions.postLoginDataRejected(error))
      })
  }
}
