import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const loadTimelogs = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch(actions.getTimelogs())
    return Axios.get(`${API_URL}/timelogs?${query}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getTimelogsFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getTimelogsRejected(error))
      })
  }
}

export const loadTimelog = (id) => {
  return dispatch => {
    dispatch(actions.getTimelogById())
    return Axios.get(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getTimelogByIdFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getTimelogByIdRejected(error))
      })
  }
}

export const loadTimelogTms = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch(actions.getTimelogsTms())
    return Axios.get(`${API_URL}/timelogs/tms?${query}`, { headers: headers })
      // return Axios.get(`${API_URL}/timelogs/tms?endDate=Sat%20Dec%2031%202022%2019%3A20%3A42%20GMT%2B0800&limit=10&page=1&search=&sort=desc&startDate=Sat%20Jan%2001%202022%2019%3A20%3A37%20GMT%2B0800`, { headers: headers })

      .then(function (response) {
        return dispatch(actions.getTimelogsTmsFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getTimelogsTmsRejected(error))
      })
  }
}

export const submitTimelog = (data) => {
  return dispatch => {
    dispatch(actions.createTimelog())
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.createTimelogFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.createTimelogRejected(error))
      })
  }
}

export const updateTimelog = (data) => {
  return dispatch => {
    dispatch(actions.updateTimelog())
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.updateTimelogFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.updateTimelogRejected(error))
      })
  }
}

export const deleteTimelog = (id) => {
  return dispatch => {
    dispatch(actions.removeTimelog())
    return Axios.delete(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.removeTimelogFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.removeTimelogRejected(error))
      })
  }
}











