import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Form, Input, Row, Col, Button, notification } from 'antd'
import { submitLogin } from '../actions'
import TermsAndConditions from './termsAndConditions'
import './index.scss'
const Login = (props) => {
    const dispatch = useDispatch();
    const { loggingIn, loginRejected } = useSelector(state => state.login)

    const onFinish = (values) => {
        dispatch(submitLogin(values)).then(res => {
            if (res.type === 'login/postLoginDataRejected') {
                notification.warning({ message: 'Invalid!', description: `Invalid username or password.` });
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
    };

    return (
        <Fragment>
            <Row justify='center'>
                <Col lg={12} md={12} sm={24} xs={24} className='left-panel'>
                    <div className='login-container'>
                        <div className='login-form'>
                            <div className='title-header'>
                                <h1>Log in</h1>
                            </div>
                            {/* {loginRejected &&
                                <div className='login-error-message'>
                                    <span>Login Failed: Invalid Credentials</span>
                                </div>} */}
                            <Form
                                name='basic'
                                onFinish={(e) => onFinish(e)}
                            >
                                <h4>Email</h4>
                                <Form.Item
                                    name='email'
                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                >
                                    <Input className='primary-input' />
                                </Form.Item>

                                <h4>Password</h4>
                                <Form.Item
                                    name='password'
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password className='primary-input' />
                                </Form.Item>

                                <Checkbox checked={false} disabled onChange={() => this.handleAgree()}>Check here to indicate that you have read and <br /> agree to the<span onClick={e => this.handleViewTermsAndConditions(e)}><a> Terms and Conditions</a></span></Checkbox>

                                <Form.Item>
                                    <div style={{ display: 'flex' }}>
                                        <Button type='primary' style={{ width: '100%', marginTop: 10 }} disabled={loggingIn} className='primary-btn' loading={loggingIn} htmlType='submit'>
                                            Log in
                                        </Button>
                                    </div>
                                </Form.Item>

                                <div className='login-reminder'>
                                    {/* <p>Your login details are provided to you via your registered email <br /> address at the Property Management Office (PMO)</p> */}
                                    <p>Forgot your password? Contact the PMO for assistance..</p>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='right-panel' style={{ backgroundImage: `url(https://www.startupguys.net/wp-content/uploads/2022/09/human-resources-1024x683.jpg)` }}>
                    <div className='website-name'>
                        <h1>Century Communities</h1>
                        <p>HUMAN RESOURCE MANAGEMENT PORTAL</p>
                    </div>
                </Col>
            </Row>
            {/* <Modal forceRender title="Terms and Conditions" visible={this.state.showTermsAndConditions} width={600} onCancel={() => this.setState({ showTermsAndConditions: false })} footer={null}>
                <TermsAndConditions />
            </Modal> */}
        </Fragment>
    )
}

export default Login