
import { createSlice } from "@reduxjs/toolkit";

const dependents = createSlice({
  name: 'dashboard',
  initialState: {
    getReportsLoading: false,
    getReportsSuccess: false,
    getReportsRejected: false,
    getReportsData: {},
  },
  reducers: {
    getReports(state, action) {
      return {
        ...state,
        getReportsLoading: true,
        getReportsSuccess: false,
        getReportsRejected: false
      }
    },
    getReportsFulfilled(state, action) {
      return {
        ...state,
        getReportsLoading: false,
        getReportsSuccess: true,
        getReportsData: action.payload
      }
    },
    getReportsRejected(state, action) {
      return {
        ...state,
        getReportsLoading: false,
        getReportsRejected: true
      }
    },
  }
});

export const actions = dependents.actions;

export default dependents.reducer;