
import { createSlice } from "@reduxjs/toolkit";

const medicalHistories = createSlice({
    name: 'medicalHistories',
    initialState: {
        getLoading: false,
        getSuccess: false,
        getRejected: false,
        getData: {
            data: []
        },
        getByIdLoading: false,
        getByIdSuccess: false,
        getByIdRejected: false,
        getByIdData: {},
        updateLoading: false,
        updateSuccess: false,
        updateRejected: false,
        createLoading: false,
        createSuccess: false,
        createRejected: false,
        removeLoading: false,
        removeSuccess: false,
        removeRejected: false,
    },
    reducers: {
        get(state, action) {
            return {
                ...state,
                getLoading: true,
                getSuccess: false,
                getRejected: false
            }
        },
        getFulfilled(state, action) {
            return {
                ...state,
                getLoading: false,
                getSuccess: true,
                getData: action.payload
            }
        },
        getRejected(state, action) {
            return {
                ...state,
                getLoading: false,
                getRejected: true
            }
        },
        getById(state, action) {
            return {
                ...state,
                getByIdLoading: true,
                getByIdSuccess: false,
                getByIdRejected: false
            }
        },
        getByIdFulfilled(state, action) {
            return {
                ...state,
                getByIdLoading: false,
                getByIdSuccess: true,
                getByIdData: action.payload
            }
        },
        getByIdRejected(state, action) {
            return {
                ...state,
                getByIdLoading: false,
                getByIdRejected: true
            }
        },
        create(state, action) {
            return {
                ...state,
                createLoading: true,
                createSuccess: false,
                createRejected: false
            }
        },
        createFulfilled(state, action) {
            return {
                ...state,
                createLoading: false,
                createSuccess: true,
                createData: action.payload
            }
        },
        createRejected(state, action) {
            return {
                ...state,
                createLoading: false,
                createRejected: true
            }
        },
        update(state, action) {
            return {
                ...state,
                updateLoading: true,
                updateSuccess: false,
                updateRejected: false
            }
        },
        updateFulfilled(state, action) {
            return {
                ...state,
                updateLoading: false,
                updateSuccess: true,
                updateData: action.payload
            }
        },
        updateRejected(state, action) {
            return {
                ...state,
                updateLoading: false,
                updateRejected: true
            }
        },
        remove(state, action) {
            return {
                ...state,
                removeLoading: true,
                removeSuccess: false,
                removeRejected: false
            }
        },
        removeFulfilled(state, action) {
            return {
                ...state,
                removeLoading: false,
                removeSuccess: true,
                removeData: action.payload
            }
        },
        removeRejected(state, action) {
            return {
                ...state,
                removeLoading: false,
                removeRejected: true
            }
        }
    }
});

export const actions = medicalHistories.actions;

export default medicalHistories.reducer;