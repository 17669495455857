export const defaultRoles = [
    {
        title: 'dashboard',
        visible: false,
        roles: {
            view: false
        }
    },
    {
        title: 'auditTrails',
        visible: false,
        roles: {
            view: false,
        }
    },
    {
        title: 'reports',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
        }
    },
    {
        title: 'projects',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
        }
    },
    {
        title: 'positions',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            manageRoles: false,
        }
    },
    {
        title: 'announcements',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            approve: false,
        }
    },
    {
        title: 'memorandums',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
        }
    },
    {
        title: 'overtimes',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,
        }
    },
    {
        title: 'noticeOfOfficialBusiness',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'leaveApplications',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'employeeMasterfile',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'employmentHistories',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'disciplinaryActions',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'educations',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'accountabilities',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'dependents',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'documents',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'personalMovements',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'fillingReports',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'timeRequests',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'appraisalPerformances',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'references',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'medicalHistories',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'allowances',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'biometrics',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'timelogs',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false
        }
    },
    {
        title: 'utilities',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            importDataFromPromis: false,
            positionAccessDefinition: false,
            manageAccess: false
        }
    },
    {
        title: 'payrolls',
        visible: false,
        roles: {}
    },
]