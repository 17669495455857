import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Form, Select, notification, Card } from 'antd'
import { loadById, update, create } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import { upload } from '../../../../utilities/filesController'
import './index.scss'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '@renderer'
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'

const { Option } = Select

const FormComponent = (props) => {
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState()
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.announcements)

    useEffect(() => {
        if (path === '/announcements/create') setPageAction(PAGE_CREATE)
        else if (path === '/announcements/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                const content = (res.payload && res.payload.content) ? EditorState.createWithContent(convertFromRaw(JSON.parse(res.payload.content))) : EditorState.createEmpty()
                setEditorState(content)
                form.resetFields()
                form.setFieldsValue({ ...res.payload })
                res.payload && res.payload.images && setImages(res.payload.images)
            })
        }
    }, [])

    function handleSubmit(value) {
        const submitData = {
            ...value, content: convertToRaw(editorState.getCurrentContent()),
        }
        setLoading(true)

        if (pageAction === PAGE_CREATE) {
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(create({ ...submitData, images: [...images, ...listOfImageObject] })).then(res => {
                        if (res.type === 'announcements/createAnnouncementFulfilled') {
                            notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                            goBack()
                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        else if (pageAction === PAGE_VIEW) {
            const announcementId = id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(
                        update({ id: announcementId, images: [...images, ...listOfImageObject], ...submitData })
                    ).then(res => {
                        setLoading(false)
                        if (res.type === 'announcements/updateAnnouncementFulfilled') {
                            notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                            goBack()
                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })

        }
        else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    };

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {
                    console.log("item, item.name, item.uploadDir", item, item.name, item.uploadDir, item)
                    upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    function onEditorStateChange(editorState) {
        setEditorState(editorState);
    };

    function uploadCallback(file) {
        return upload(file, file.name, 'images').then(data => {
            return new Promise(
                (resolve, reject) => { resolve({ data: { link: data.completeUrl } }); }
            );
        }).catch(err => { })
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }
    const isAccessDisabled = (!access.check('announcements', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='Announcements'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`announcement-${props.selectedId}`}
                form={form}

                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="AttachedUrl"
                            name="attachedUrl"
                            rules={[{ required: true, message: 'Please input attached url!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please input type!' }]}
                        >
                            <Select disabled={isAccessDisabled} >
                                <Option value='ANNOUNCEMENT'>ANNOUNCEMENTS</Option>
                                <Option value='EVENT'>EVENTS</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col md={24} lg={24} sm={24} xs={24}   >
                        <Editor
                            readOnly={isAccessDisabled}
                            editorState={editorState}
                            toolbarClassName="rdw-storybook-toolbar"
                            wrapperClassName="rdw-storybook-wrapper"
                            editorClassName="rdw-storybook-editor"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{ image: { uploadCallback: uploadCallback, alignmentEnabled: true, previewImage: true, urlEnabled: false, alt: { present: true, mandatory: false }, } }}
                        />
                    </Col>
                </Row>

                <Row>
                    <CustomDropzoneMultiple
                        disabled={isAccessDisabled}
                        fileName='announcements'
                        uploadDir='announcements'
                        height={200}
                        width={200}
                        getFile={file => { getFileImage(file) }}
                    />
                </Row>

                <Row>
                    <div className='d-flex'>
                        {
                            images.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <ImagePresignedUrl src={item.url} style={{ width: 200, height: 150 }} />
                                        {
                                            ((access.check('announcements', 'add') && (pageAction === PAGE_CREATE)) || (access.check('announcements', 'edit') && (pageAction === PAGE_VIEW))) &&
                                            <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Row>
                {
                    ((access.check('announcements', 'add') && (pageAction === PAGE_CREATE)) || (access.check('announcements', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading || loading} disabled={getByIdLoading || createLoading || updateLoading || loading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Card>
    )
}


export default FormComponent