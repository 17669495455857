import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, notification, Card, Form, Select, DatePicker } from 'antd'
import { loadById, create, update } from '../actions'
import { load as loadEmployees } from '../../employee/actions';
import moment from 'moment'
import { load as loadProjects } from '../../projects/actions'
import access from '@access'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import Toolbar from '@toolbar'
import './index.scss'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '@renderer'
import { upload } from '../../../../utilities/filesController'

const { Option } = Select
const FormBusinessTrip = (props) => {
    const [form] = Form.useForm()
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [search, setSearch] = useState('')
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const [listOfImageObject, setListOfImageObject] = useState([])
    const employees = useSelector(state => state.admin.employees)
    const projects = useSelector(state => state.admin.projects)
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.businessTrips)
    const userId = getByIdData.user && getByIdData.user._id
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])

    useEffect(() => {
        if (path === '/business-trips/create') setPageAction(PAGE_CREATE)
        else if (path === '/business-trips/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    timeIn: res.payload && moment(res.payload.timeIn),
                    timeOut: res.payload && moment(res.payload.timeOut),
                })
                res.payload && res.payload.images && setImages(res.payload.images)
            })

            if (userId) dispatch(loadEmployees({ page: 1, limit: 50, id: userId, }))
        }
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
            dispatch(loadProjects({ page: 1, limit: 50, search: search, }))
        }, 1000);
        return () => clearTimeout(timerId);
    }, [search]);

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {
                    upload(item, item.name, item.uploadDir).then(data => { return resolve(true) }).catch(err => {
                        return notification.error({ message: `Failed to upload image`, description: err, });
                    })
                })
            } else { return resolve(true) }
        })
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    function handleSubmit(value) {

        const submitData = { ...value, }

        if (pageAction === PAGE_CREATE) {
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(create({ images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        if (res.type === 'businessTrips/createFulfilled') {
                            notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                            goBack()
                        }
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })

        } else if (pageAction === PAGE_VIEW) {
            Promise.all([uploadFileImage()]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    const documentId = id
                    dispatch(update({ id, images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        if (res.type === 'businessTrips/updateFulfilled') {
                            notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                            goBack()
                        }
                    })
                } else {
                    notification.error({ message: `Failed to update data`, });
                    setLoading(false)
                }
            })

        } else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }


    const isAccessDisabled = (!access.check('noticeOfOfficialBusiness', 'edit') && (pageAction === PAGE_VIEW))
    return (
        <Card bordered={false}>
            <Toolbar
                title='Notice of Official Business'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`businessTrip`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        {
                            (pageAction === PAGE_CREATE) &&
                            <Form.Item label="User" name="userId" rules={[{ required: true, message: 'Please input user!' }]}   >
                                <Select placeholder='Search Employee Name' style={{ width: '100%' }} defaultValue={userId} showSearch onSearch={(value) => setSearch(value)} optionFilterProp="children" loading={employees.getLoading}>
                                    {
                                        employees.getData.data.map((data, index) => {
                                            console.log("data", data.firstName)
                                            return (
                                                <Option key={data._id} value={data._id}>{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.email})</span></Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input title!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Please input description!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        {/* <Form.Item
                            label="Cover Trip"
                            name="coverTrip"
                            rules={[{ required: true, message: 'Please input cover trip!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item> */}

                        <Form.Item
                            label="Destination"
                            name="projectId"
                            rules={[{ required: true, message: 'Please input destination!' }]}
                        >
                            <Select disabled={isAccessDisabled} >
                                {
                                    projects.getData.data.map((data, index) => {
                                        return (
                                            <Option value={data._id} key={index}>{data.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Purposes"
                            name="purpose"
                            rules={[{ required: true, message: 'Please input purposes!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        <Form.Item
                            label="Time In"
                            name="timeIn"
                            rules={[{ required: true, message: 'Please input time in!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Time Out"
                            name="timeOut"
                            rules={[{ required: true, message: 'Please input time out!' }]}
                        >
                            <DatePicker
                                disabled={isAccessDisabled}
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ message: 'Please input remarks!' }]}
                        >
                            <Input disabled={isAccessDisabled} />
                        </Form.Item>

                        {
                            (pageAction === PAGE_VIEW) &&
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{ required: true, message: 'Please input status!' }]}
                            >
                                <Select disabled={isAccessDisabled} >
                                    <Option value={1}>FOR APPROVAL</Option>
                                    {
                                        access.check('noticeOfOfficialBusiness', 'approve') &&
                                        <Option value={2}>APPROVED</Option>
                                    }
                                    {
                                        access.check('noticeOfOfficialBusiness', 'cancel') &&
                                        <Option value={5}>CANCELLED</Option>
                                    }
                                    {
                                        access.check('noticeOfOfficialBusiness', 'reject') &&
                                        <Option value={6}>REJECTED</Option>
                                    }
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <CustomDropzoneMultiple
                            disabled={isAccessDisabled}
                            fileName='trips'
                            uploadDir='trips'
                            height={200}
                            width={200}
                            getFile={file => { getFileImage(file) }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} xs={24} sm={24}>
                        <div className='d-flex'>
                            {
                                images.map((item, index) => {

                                    return (
                                        <div key={index} >
                                            <ImagePresignedUrl src={item.url} style={{ width: 200, height: 150 }} />
                                            {
                                                ((access.check('noticeOfOfficialBusiness', 'add') && (pageAction === PAGE_CREATE)) || (access.check('noticeOfOfficialBusiness', 'edit') && (pageAction === PAGE_VIEW))) &&
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
                {
                    ((access.check('noticeOfOfficialBusiness', 'add') && (pageAction === PAGE_CREATE)) || (access.check('noticeOfOfficialBusiness', 'edit') && (pageAction === PAGE_VIEW))) &&
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={getByIdLoading || createLoading || updateLoading} disabled={getByIdLoading || createLoading || updateLoading}  >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Card>
    )
}

export default FormBusinessTrip