import { combineReducers } from 'redux'

// Login
import loginReducers from '../components/login/reducers'

// Client
import clientReducers from '../components/client/reducers'

// Admin
import adminReducers from '../components/admin/reducers'

const rootReducer = combineReducers({
  login: loginReducers,
  client: clientReducers,
  admin: adminReducers,
})

export default rootReducer
