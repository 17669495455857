import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadById = (id) => {
  return dispatch => {
    dispatch(actions.getById())
    return Axios.get(`${API_URL}/user/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getByIdFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getByIdRejected(error))
      })
  }
}

export const update = (data) => {
  return dispatch => {
    dispatch(actions.update())
    return Axios.put(`${API_URL}/user/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.updateFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.updateRejected(error))
      })
  }
}

export const changePasswordAdmin = (data) => {
  return dispatch => {
    dispatch(actions.update())
    return Axios.put(`${API_URL}/change-password/${data.userId}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.updateFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.updateRejected(error))
      })
  }
}
