import React, { useEffect, useState } from 'react'
import { Card, Layout, Row, Col, Pagination, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { load } from '../actions'
import './index.scss'
import StatusIconDisplay from '@status-icon'
import FormComponent from './form'
import { NONE } from '@page-actions'
import Toolbar from '@toolbar'
import moment from 'moment'

const PerformancesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})
    const [ratings, setRatings] = useState(0)
    const [modalShow, setModalShow] = useState(false)
    const dispatch = useDispatch();
    const { getData, getLoading, createSuccess, updateSuccess, removeSuccess } = useSelector(state => state.client.performances)

    useEffect(() => {
        dispatch(load(pagination))
    }, [pagination])

    useEffect(() => {
        setModalShow(false)
        dispatch(load(pagination))
    }, [updateSuccess, createSuccess, removeSuccess])

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    function performanceAverage(value) {
        if (value <= 69) {
            return <span style={{ color: 'red' }}>BELOW POOR PERFORMANCE</span>
        } else if (value >= 70 && value <= 74) {
            return <span style={{ color: '#e84f4f' }}>NEEDS IMPROVEMENT</span>
        } else if (value >= 75 && value <= 84) {
            return <span style={{ color: '#a0a651' }}>AVERAGE</span>
        } else if (value >= 85 && value <= 94) {
            return <span style={{ color: '#499141' }}>ABOVE AVERAGE</span>
        } else if (value >= 95 && value <= 100) {
            return <span style={{ color: 'green' }}>EXCELLENT</span>
        } else {
            setRatings(0)
            return ''
        }
    }
    return (
        <Layout className='performances-page'>
            <Card bordered={false}>
                <Toolbar
                    title='Performances'
                    handleSearch={(search) => setPagination({ ...pagination, search })}
                />
                <Table columns={[
                    {
                        title: 'Ratings',
                        dataIndex: 'ratings',
                        key: 'ratings',
                        render: (item, data) => {
                            return <span>({data.ratings}%) <br />{performanceAverage(data.ratings)}<StatusIconDisplay status={data.status} /></span>
                        }
                    },
                    {
                        title: 'Date Covered From',
                        dataIndex: 'dateCoveredFrom',
                        key: 'dateCoveredFrom',
                        render: (item, data) => {
                            return <span>{moment(data.dateCovered).format('LL')}</span>
                        }
                    },
                    {
                        title: 'Date Covered To',
                        dataIndex: 'dateCoveredTo',
                        key: 'dateCoveredTo',
                        render: (item, data) => {
                            return <span>{moment(data.dateCovered).format('LL')}</span>
                        }
                    },
                    {
                        title: 'Remarks',
                        dataIndex: 'remarks',
                        key: 'remarks',
                        responsive: ['lg', 'md', 'sm'],
                    },
                ]} dataSource={getData.data} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                <Row style={{ marginTop: 30, width: '100%' }}>
                    <Col md={24} lg={24} sm={24} xs={24} align="right" >
                        <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                    </Col>
                </Row>
            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

export default PerformancesComponent

