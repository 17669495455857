export const getToken = () => {
  try {
    let sessionName = 'AMS_SESSION_TOKEN'
    return window.localStorage.getItem(sessionName)
  } catch (error) { }
}

export const headers = {
  "Authorization": `${getToken()}`,
  "Accept": "application/json",
  "Content-Type": "application/json"
}
