import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const load = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch(actions.getAnnouncements())
    return Axios.get(`${API_URL}/announcements?${query}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getAnnouncementsFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getAnnouncementsRejected(error))
      })
  }
}

export const loadAnnouncementsAll = () => {
  return dispatch => {
    dispatch(actions.getAnnouncementsAll())
    return Axios.get(`${API_URL}/announcements-all`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getAnnouncementsAllFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getAnnouncementsAllRejected(error))
      })
  }
}

export const loadById = (id) => {
  return dispatch => {
    dispatch(actions.getAnnouncementById())
    return Axios.get(`${API_URL}/announcement/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getAnnouncementByIdFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getAnnouncementByIdRejected(error))
      })
  }
}

export const create = (data) => {
  return dispatch => {
    dispatch(actions.createAnnouncement())
    return Axios.post(`${API_URL}/announcement`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.createAnnouncementFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.createAnnouncementRejected(error))
      })
  }
}

export const update = (data) => {
  return dispatch => {
    dispatch(actions.updateAnnouncement())
    return Axios.put(`${API_URL}/announcement/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.updateAnnouncementFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.updateAnnouncementRejected(error))
      })
  }
}

export const remove = (id) => {
  return dispatch => {
    dispatch(actions.removeAnnouncement())
    return Axios.delete(`${API_URL}/announcement/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.removeAnnouncementFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.removeAnnouncementRejected(error))
      })
  }
}

