import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Pagination, Table, Card, Space, Button, Popconfirm, notification, Avatar } from 'antd'
import { load, remove } from '../actions'
import './index.scss'
import { ImagePresignedUrl } from '@renderer'
import { serviceStatusTag } from '../../shared/components/serviceStatusTag'
import Toolbar from '@toolbar'
import access from '../../../../utilities/access'
import Papa from 'papaparse'
import moment from 'moment'
import Permissions from '@permissions'
import { useRouteMatch } from 'react-router-dom';

const List = (props) => {
    const [tableData, setTableData] = useState([])
    const [search, setSearch] = useState('')
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const prevPagination = useRef(pagination)
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.admin.employmentHistories)

    useEffect(() => {
        if (prevPagination.current !== pagination) dispatch(load(pagination))
    }, [pagination])

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        setTableData([...getData.data])
    }, [getData])

    function handleDelete(id) {
        dispatch(remove(id)).then(res => {
            if (res.type === 'employmentHistories/removeFulfilled') {
                notification.success({ message: 'Deleted', description: 'You have successfully deleted a data.' });
                dispatch(load(pagination))
            }
        })
    }

    useEffect(() => {
        if (search) {
            const delayDebounceFn = setTimeout(() => {
                setPagination(prevState => ({ ...prevState, search }));
            }, 1300)
            return () => clearTimeout(delayDebounceFn)
        } else dispatch(load({ ...pagination, search: '' }))
    }, [search])

    function handleView(data) {
        props.history.push(`/employment-histories/view/${data._id}`)
    }


    function handleDownload() {
        console.log("DOWNLOAD CSV", tableData)
        const exportData = tableData.map(data => {
            return {
                "Created Date": moment(data.createdAt).format('LLLL'),
                "Name": `${data.user && data.user.firstName} ${data.user && data.user.lastName}`,
                "Company Name": data.companyName,
                "Company Address": data.companyAddress,
                "Phone Number": data.phoneNumber,
                "Position": data.position,
                "Reason For Leaving": data.reasonForLeaving,
                "Year": moment(data.year).format('YYYY'),
            }
        })
        var csv = Papa.unparse(exportData);
        var unix = Date.now()
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `emp_histories_${unix}.csv`);
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `emp_histories_${unix}.csv`);
        tempLink.click();
    }

    const dataSource = tableData
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            render: (item, data) => {
                return (<Avatar size={44} src={<ImagePresignedUrl src={data.user && data.user.image && data.user.image[0] && data.user.image[0].url} style={{ width: 64, height: '100%' }} objectFit='cover' />} />)
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (item, data) => {
                return (<span style={{ textTransform: 'capitalize' }}>{data.user && data.user.firstName} {data.user && data.user.lastName}</span>)
            }
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'employmentHistoryName',
            responsive: ['lg', 'md', 'sm'],
        },
        {
            title: 'Company Address',
            dataIndex: 'companyAddress',
            key: 'companyAddress',
            responsive: ['lg', 'md', 'sm'],

        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            responsive: ['lg', 'md', 'sm'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (item) => {
                return (<span>{serviceStatusTag(item)}</span>)
            }
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (item, data) => {
                return (
                    <Space>
                        <Button type='primary' size='small' onClick={() => handleView(data)}  >
                            View
                        </Button>
                        {(access.check('employementHistories', 'delete') && data.status === 1) &&
                            <Button type='danger' size='small'>
                                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                    Delete
                                </Popconfirm>
                            </Button>
                        }
                    </Space>
                )
            }
        },
    ];
    return (
        <Layout className='page' >
            <Permissions module='employmentHistories' role='view' >
                <Card bordered={false}>
                    <Row gutter={32}>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Toolbar
                                title='Employment Histories'
                                handleAdd={() => props.history.push(`${path}/create`)}
                                removeAdd={!access.check('employmentHistories', 'add')}
                                handleDownload={() => handleDownload()}
                                handleSearch={(search) => setSearch(search)}
                                handleSort={(sort) => setPagination({ ...pagination, sort })}
                                handleStatus={(status) => setPagination({ ...pagination, status })}
                                statusArray={[
                                    { id: 0, label: 'All' },
                                    { id: 1, label: 'For Approval' },
                                    { id: 2, label: 'Approved' },
                                    { id: 5, label: 'Cancelled' },
                                    { id: 6, label: 'Rejected' },
                                ]}
                            />
                            <Table columns={columns} dataSource={dataSource} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                            <Row style={{ marginTop: 30, width: '100%' }}>
                                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Permissions>
        </Layout>
    )
}

export default List