import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Card, Form, Divider, notification, DatePicker, Select } from 'antd'
import { loadById, update, create } from '../actions'
import { CustomDropzone } from '../../../../utilities/customDropzone'
import { upload } from '../../../../utilities/filesController'
import { load as loadEmployees } from '../../employee/actions';
import moment from 'moment'
import './index.scss'
import { PAGE_VIEW, PAGE_CREATE } from '@page-actions'
import { load as loadPositions } from '../../positions/actions'
import { load as loadProjects } from '../../projects/actions'
import { useParams, useRouteMatch } from 'react-router-dom';
import session from '@session'
import Toolbar from '@toolbar'

const { Option } = Select
const FormAdmin = (props) => {
    const [form] = Form.useForm()
    const [userDetails, setUserDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [fileImage, setFileImage] = useState(undefined)
    const [fileImageObj, setFileImageObj] = useState(undefined)
    const [isPositionChanged, setIsPositionChanged] = useState(false)
    const [pageAction, setPageAction] = useState(PAGE_CREATE)
    const [search, setSearch] = useState('')
    const { id } = useParams();
    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const employees = useSelector(state => state.admin.employees)
    const positions = useSelector(state => state.admin.positions)
    const { getByIdData, getByIdLoading, createLoading, updateLoading } = useSelector(state => state.admin.administrators)
    const userId = getByIdData.user && getByIdData.user._id

    useEffect(() => {
        if (path === '/administrators/create') setPageAction(PAGE_CREATE)
        else if (path === '/administrators/view/:id') {
            setPageAction(PAGE_VIEW)
            dispatch(loadById(id)).then(res => {
                form.resetFields()
                form.setFieldsValue({
                    ...res.payload,
                    dateHired: res.payload && moment(res.payload.dateHired),
                    dateResigned: res.payload && moment(res.payload.dateResigned),
                    birthday: res.payload && moment(res.payload.birthday),
                })
                setUserDetails(res.payload)
            })

            if (userId) {

                dispatch(loadEmployees({ page: 1, limit: 50, }))
            }
        }
        dispatch(loadProjects({ page: 1, limit: 100, }))
        dispatch(loadPositions({ page: 1, limit: 100, }))
    }, [])

    useEffect(() => {
        if (!search.length) return
        const timerId = setTimeout(() => {
            dispatch(loadEmployees({ page: 1, limit: 50, search: search, }))
        }, 1000);
        return () => clearTimeout(timerId);
    }, [search]);

    function handleSubmit(value) {

        const submitData = { ...value, }
        setLoading(true)
        if (pageAction === PAGE_CREATE) {
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                const submitData = {
                    ...value,
                    postById: session.user().id,
                    positionDate: isPositionChanged ? new Date : undefined
                }
                if (fileImageObj) submitData.image = fileImageObj
                if (isAllResultTrue) {
                    dispatch(create({ ...submitData })).then(res => {
                        if (res.type === 'administrators/createFulfilled') {
                            notification.success({ message: 'Created', description: 'You have successfully created a data.' });
                            goBack()
                            setLoading(false)
                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                        // description: err,
                    });
                    setLoading(false)
                }
            })

        } else if (pageAction === PAGE_VIEW) {
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                const submitData = { id, ...value, postById: session.user().id, positionDate: isPositionChanged ? new Date : undefined }
                if (fileImageObj) submitData.image = fileImageObj
                if (isAllResultTrue) {
                    delete value.religion
                    dispatch(update({ id, ...submitData })).then(res => {
                        if (res.type === 'administrators/updateFulfilled') {
                            notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
                            goBack()
                            setLoading(false)

                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                        // description: err,
                    });
                    setLoading(false)
                }
            })

        } else {
            notification.warning({ message: 'Opppps!', description: `Can't Submit Data.` });
        }
    }

    function goBack() {
        form.resetFields()
        props.history.goBack()
    }


    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (fileImage) {
                upload(fileImage, fileImage.name, 'images').then(data => {
                    return resolve(true)
                }).catch(err => {
                    return notification.error({ message: `Failed to upload image`, description: err, });
                })
            } else {
                return resolve(true)
            }
        })
    }

    function getFileImage(e) {
        const obj = {
            name: e.name,
            url: `images/${e.name}`
        }
        setFileImage(e)
        setFileImageObj(obj)
    }

    return (
        <Card bordered={false}>
            <Toolbar
                title='Administrators'
                handleBack={() => props.history.goBack()}
            />
            <Form
                name={`admin-${props.selectedId}`}
                form={form}
                onFinish={(e) => handleSubmit(e)}
            // onFinishFailed={onFinishFailed}
            >
                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={24} xs={24} lg={24} sm={24}>
                        <div className='user-details' >
                            <div className='user-view-left'>
                                <CustomDropzone
                                    fileName='profile'
                                    height={200}
                                    width={200}
                                    getFile={file => { getFileImage(file) }}
                                    value={userDetails.image && userDetails.image[0] && userDetails.image[0].url}
                                />
                                <div className='details'>
                                    <p className='name'>{`${userDetails.firstName || ''} ${userDetails.lastName || ''}`}</p>
                                    <p className='birthdate'>Birthday:  {userDetails.birthday ? `${moment(userDetails.birthday).format('LL')}` : ''}</p>

                                    <div className='ratings'>
                                        <p>JOB LEVEL</p>
                                        <div className='ratings-flex'>
                                            <span className='job-level-label'>{userDetails.position && userDetails.position.jobLevel && userDetails.position.jobLevel.toFixed(1)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={12} style={{ width: '100%' }}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true, message: 'Please input first name!' }]}
                        >
                            <Input value={userDetails.firstName} onChange={(e) => {
                                setUserDetails({ ...userDetails, firstName: e.target.value })
                            }} />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please input last name!' }]}
                        >
                            <Input
                                value={userDetails.lastName} onChange={(e) => {
                                    setUserDetails({ ...userDetails, lastName: e.target.value })
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Middle Name"
                            name="middleName"
                            rules={[{ required: true, message: 'Please input middle name!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input email!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Contact"
                            name="contact"
                            rules={[{ required: true, message: 'Please input contact!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: 'Please input address!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Position"
                            name="positionId"
                            rules={[{ required: true, message: 'Please input position!' }]}
                        >
                            <Select onChange={({ }, e) => {
                                const jobLevel = e.data.jobLevel
                                console.log("jobLevel", jobLevel)
                                setIsPositionChanged(true)
                                setUserDetails({
                                    ...userDetails, ...{
                                        position: {
                                            jobLevel
                                        }
                                    }
                                })
                            }}>
                                {
                                    positions.getData.data.map((item, index) => {
                                        return (
                                            <Option value={item._id} data={item} key={index}>{item.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Birthday"
                            name="birthday"
                            rules={[{ required: true, message: 'Please input birthday!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '100%' }}
                                value={userDetails.birthday} onChange={(e) => {
                                    setUserDetails({ ...userDetails, birthday: e })
                                }}
                            />
                        </Form.Item>


                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[{ required: true, message: 'Please input gender!' }]}
                        >
                            <Select>
                                <Select.Option value={1}>Male</Select.Option>
                                <Select.Option value={2}>Female</Select.Option>
                            </Select>
                        </Form.Item>


                    </Col>
                </Row>

                {
                    (pageAction === PAGE_CREATE) &&
                    <Row gutter={16}>
                        <Divider />
                        <Col md={8} lg={8} sm={24} xs={24} >
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please input password!' }]}
                            >
                                <Input placeholder='Password' />
                            </Form.Item>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col md={24} lg={24} sm={24} align={'right'}>
                        <Form.Item >
                            <Button className='primary-btn' type="primary" htmlType="submit"
                                loading={getByIdLoading || createLoading || updateLoading || loading} disabled={getByIdLoading || createLoading || updateLoading || loading}  >
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default FormAdmin