export const PAGE_VIEW = 'PAGE_VIEW'
export const PAGE_CREATE = 'PAGE_CREATE'

export const isPageCreate = (type) => {
    if (type === PAGE_CREATE) return true
    else return false
}

export const isPageView = (type) => {
    if (type === PAGE_VIEW) return true
    else return false
}