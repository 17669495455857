
import { createSlice } from "@reduxjs/toolkit";

const announcements = createSlice({
    name: 'announcements',
    initialState: {
        getLoading: false,
        getSuccess: false,
        getRejected: false,
        getData: {
            data: []
        },
        viewerLoading: false,
        viewerSuccess: false,
        viewerRejected: false,
    },
    reducers: {
        get(state, action) {
            return {
                ...state,
                getLoading: true,
                getSuccess: false,
                getRejected: false
            }
        },
        getFulfilled(state, action) {
            return {
                ...state,
                getLoading: false,
                getSuccess: true,
                getData: action.payload
            }
        },
        getRejected(state, action) {
            return {
                ...state,
                getLoading: false,
                getRejected: true
            }
        },
        viewer(state, action) {
            return {
                ...state,
                viewerLoading: true,
                viewerSuccess: false,
                viewerRejected: false
            }
        },
        viewerFulfilled(state, action) {
            return {
                ...state,
                viewerLoading: false,
                viewerSuccess: true,
                viewerData: action.payload
            }
        },
        viewerRejected(state, action) {
            return {
                ...state,
                viewerLoading: false,
                viewerRejected: true
            }
        }
    }
});

export const actions = announcements.actions;

export default announcements.reducer;