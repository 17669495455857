import React from 'react'
import { Layout, Tabs, Card } from 'antd'
import PersonalInformationComponent from './personalInformation'
import ChangePassword from './changePassword';
const { TabPane } = Tabs;
const Profile = (props) => {
    return (
        <Layout>
            <Card bordered={false}>
                <Tabs defaultActiveKey="1" size='small' style={{ marginBottom: 32 }}>
                    <TabPane tab="Information" key="1">
                        <PersonalInformationComponent />
                    </TabPane>
                    <TabPane tab="Change Password" key="2">
                        <ChangePassword {...props} />
                    </TabPane>
                </Tabs>
            </Card>
        </Layout>
    )
}

export default Profile