
import React, { useEffect, useState, useRef } from 'react'
import { Layout, Menu, Spin, Divider, Avatar } from 'antd';
import { useDispatch } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
// import NotFound from '../404'
import './index.scss'
import _ from 'lodash'
import { ImagePresignedUrl } from '../../../utilities/renderer'
import session from '@session'
import { userType } from '@enums'
import { loadById as loadAdmin } from '../../admin/admins/actions'
import { loadById as loadEmployee } from '../../client/profile/actions'

const { Sider, Content } = Layout;

const SiderComponent = (props) => {
    const dispatch = useDispatch();

    const siderRef = useRef(null);
    // const { getCurrentUserAdminData } = useSelector(state => state.admin.administrators)
    const getCurrentUserAdminData = { roles: [] }
    const roles = getCurrentUserAdminData.roles
    const [userDetails, setUserDetails] = useState({})
    const [selectedKeys, setSelectedKeys] = useState('')
    const [collapse, setCollapse] = useState(false)
    const [isOnBreakpoint, setIsOnBreakpoint] = useState(false)
    const [paths, setPaths] = useState([])

    useEffect(() => {
        const { ADMIN, CLIENT } = userType
        const sessionUser = session.user()
        if (sessionUser.type === ADMIN) dispatch(loadAdmin(sessionUser._id)).then(res => setUserDetails(res.payload))
        else if (sessionUser.type === CLIENT) dispatch(loadEmployee(sessionUser._id)).then(res => setUserDetails(res.payload))
    }, [])

    useEffect(() => {
        if (props.enableRoleValidation && roles) {
            const paths = props.paths
            const newPaths = []
            paths.map(path => {
                const children = []
                Array.isArray(path.children) && path.children.map((child, index) => {
                    checkRolePageEnabled(child.key) && children.push(child)
                })

                if (children.length !== 0) path.children = children
                else delete path.children

                checkRolePageEnabled(path.key) && newPaths.push(path)
            })
            setPaths([...newPaths])
        } else setPaths(props.paths)
    }, [roles])

    useEffect(() => {
        const pathname = props.history.location.pathname
        const newPath = arrFlatten(paths).find(path => path.route === pathname)
        setSelectedKeys(newPath && newPath.key)
    }, [paths])

    function checkRolePageEnabled(key) {
        if (Array.isArray(roles)) {
            const foundData = roles && roles.find(data => data.title === key)
            if (foundData && foundData.visible) return true
            else return false
        } else {
            return false
        }
    }

    function handleChangeRoute(route) {
        const newPath = arrFlatten(paths).find(path => path.key === route)
        setSelectedKeys(newPath && newPath.key)
        props.history.push(newPath && newPath.route)
        if (isOnBreakpoint) setCollapse(!collapse)
    }

    function convertBool(stringBool) {
        if (stringBool === 'true') return true
        else return false
    }

    function arrFlatten(array) {
        array && array.map(data => {
            if (data.children) array = [...array, ...data.children]
        })
        return array
    }

    useClickListenerOutsideSider(siderRef, collapse, isOnBreakpoint, (isClickOutside, collapse, isOnBreakpoint) => {
        // only in mobile view
        if (!collapse && isOnBreakpoint && isClickOutside) {
            setCollapse(true)
        }
    });

    return (
        <Layout className='custom-sider' >
            <div className='wrapper'>
                <Spin spinning={false}>
                    <Sider width={260} breakpoint="lg"
                        collapsedWidth="0" onBreakpoint={(isBreak) => {
                            setIsOnBreakpoint(isBreak)
                        }}
                        onCollapse={(collapsed, type) => {
                            setCollapse(!collapse)
                        }}
                        collapsed={collapse}
                        ref={siderRef}>
                        <div className='header-info'>
                            <h1>HRIS MONITORING SYSTEM</h1>
                            <p>YOUR PROPERTY MANAGEMENT PORTAL</p>
                        </div>
                        <Divider />
                        <div className='user-info'>
                            <Avatar size={52} src={<ImagePresignedUrl src={userDetails.image && userDetails.image[0].url} preview={false} style={{ width: 52, height: '100%' }} objectFit='cover' />} />
                            <p>{userDetails && userDetails.firstName} {userDetails && userDetails.lastName}</p>
                            <h6>{userDetails && userDetails.employeeNumber}</h6>
                        </div>
                        <Divider />
                        <Menu selectedKeys={[selectedKeys]} items={paths} style={{ background: 'transparent' }} onClick={(e) => {
                            handleChangeRoute(e.key)
                        }} mode="inline">
                        </Menu>
                    </Sider>
                </Spin>
            </div>
            <Content className='content'>
                <Switch>
                    {/* // this will scroll to top when switch changes. */}
                    {window.scrollTo(0, 0)}
                    {_.map(arrFlatten(paths), (path, index) => {
                        return (
                            (
                                <Route
                                    exact={convertBool(path.exact)}
                                    path={path.route}
                                    // component={path.component}
                                    render={(props) =>
                                        <path.component paths={arrFlatten(paths)} />}
                                    key={path.route || index}
                                />
                            )
                        )
                    })}
                    {/* <Route component={NotFound} /> */}
                    {/* <Redirect to='/404' /> */}
                </Switch>
            </Content>
        </Layout>
    )
}

export default withRouter(SiderComponent)

function useClickListenerOutsideSider(ref, collapse, isOnBreakpoint, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(true, collapse, isOnBreakpoint)
            } else {
                callback(false, collapse, isOnBreakpoint)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, collapse, isOnBreakpoint]);
}