import jwt_decode from "jwt-decode";

const session = {

    user: () => {
        if (window.localStorage.getItem('AMS_SESSION_TOKEN')) {
            return jwt_decode(window.localStorage.getItem('AMS_SESSION_TOKEN'))
        }
    },

    token: () => {
        return window.localStorage.getItem('AMS_SESSION_TOKEN')
    },

    logout: () => {
        localStorage.removeItem('AMS_SESSION_DATA');
        localStorage.removeItem('AMS_SESSION_TOKEN');
        window.location.reload();
        window.location.replace('/');
    }
}

export default session