
import { createSlice } from "@reduxjs/toolkit";

const login = createSlice({
  name: 'login',
  initialState: {
    loggingIn: false,
    loggedIn: false,
    loginRejected: false,
    loggedData:
    {
      user: {
        username: '',
        email: ''
      }
    },
  },
  reducers: {
    postLoginData(state, action) {
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        loginRejected: false
      }
    },
    postLoginDataFulfilled(state, action) {
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loggedData: action.payload
      }
    },
    postLoginDataRejected(state, action) {
      return {
        ...state,
        loggingIn: false,
        loginRejected: true
      }
    }
  }
});

export const actions = login.actions;

export default login.reducer;