import { createSlice } from "@reduxjs/toolkit";

const projects = createSlice({
  name: 'projects',
  initialState: {
    getLoading: false,
    getSuccess: false,
    getRejected: false,
    getData: {
      data: []
    },
    getByIdLoading: false,
    getByIdSuccess: false,
    getByIdRejected: false,
    getByIdData: {},
    updateLoading: false,
    updateSuccess: false,
    updateRejected: false,
    createLoading: false,
    createSuccess: false,
    createRejected: false,
    removeLoading: false,
    removeSuccess: false,
    removeRejected: false,
    getAllLoading: false,
    getAllSuccess: false,
    getAllRejected: false,
    getAllData: []
  },
  reducers: {
    gets(state, action) {
      return {
        ...state,
        getLoading: true,
        getSuccess: false,
        getRejected: false
      }
    },
    getsFulfilled(state, action) {
      return {
        ...state,
        getLoading: false,
        getSuccess: true,
        getData: action.payload
      }
    },
    getsRejected(state, action) {
      return {
        ...state,
        getLoading: false,
        getRejected: true
      }
    },
    getsAll(state, action) {
      return {
        ...state,
        getAllLoading: true,
        getAllSuccess: false,
        getAllRejected: false
      }
    },
    getsAllFulfilled(state, action) {
      return {
        ...state,
        getAllLoading: false,
        getAllSuccess: true,
        getAllData: action.payload
      }
    },
    getsAllRejected(state, action) {
      return {
        ...state,
        getAllLoading: false,
        getAllRejected: true
      }
    },

    getInventoriesCount(state, action) {
      return {
        ...state,
        projectInventoriesCountLoading: true,
        projectInventoriesCountSuccess: false,
        projectInventoriesCountRejected: false
      }
    },
    getInventoriesCountFulfilled(state, action) {
      return {
        ...state,
        projectInventoriesCountLoading: false,
        projectInventoriesCountSuccess: true,
        projectInventoriesCountData: action.payload
      }
    },
    getInventoriesCountRejected(state, action) {
      return {
        ...state,
        projectInventoriesCountLoading: false,
        projectInventoriesCountRejected: true
      }
    },

    getById(state, action) {
      return {
        ...state,
        getByIdLoading: true,
        getByIdSuccess: false,
        getByIdRejected: false
      }
    },
    getByIdFulfilled(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdSuccess: true,
        getByIdData: action.payload
      }
    },
    getByIdRejected(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdRejected: true
      }
    },
    create(state, action) {
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createRejected: false
      }
    },
    createFulfilled(state, action) {
      return {
        ...state,
        createLoading: false,
        createSuccess: true,
        createData: action.payload
      }
    },
    createRejected(state, action) {
      return {
        ...state,
        createLoading: false,
        createRejected: true
      }
    },
    update(state, action) {
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        updateRejected: false
      }
    },
    updateFulfilled(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
        updateData: action.payload
      }
    },
    updateRejected(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateRejected: true
      }
    },
    remove(state, action) {
      return {
        ...state,
        removeLoading: true,
        removeSuccess: false,
        removeRejected: false
      }
    },
    removeFulfilled(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeSuccess: true,
        removeData: action.payload
      }
    },
    removeRejected(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeRejected: true
      }
    }
  }
});

export const actions = projects.actions;

export default projects.reducer;