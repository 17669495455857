import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTachometerAlt,
  faAngleRight,
  faUsers,
  faBriefcase,
  faCogs,
  faStar
} from '@fortawesome/free-solid-svg-icons'
import DashboardComponent from './dashboard/components'
import AnnouncementsComponent from './announcements/components'
import MemorandumsComponent from './memorandums/components'
import ReferencesComponent from './references/components'
import DependentsComponent from './dependents/components'
import EmploymentHistoriesComponent from './employmentHistories/components'
import MasterfileComponent from './employee/components'
import MedicalHistoriesComponent from './medicalHistories/components'
import EducationsComponent from './educations/components'
import DocumentsComponent from './documents/components'
import LeavesComponent from './leaves/components'
import OvertimesComponent from './overtimes/components'
import BusinessTripsComponent from './businessTrips/components'
import PersonalMovementsComponent from './personalMovements/components'
import AccountabilitiesComponent from './accountabilities/components'
import FileReportsComponent from './fileReports/components'
import TimeRequestsComponent from './timeRequests/components'
import TimelogsComponent from './timelogs/components'
import PerformancesComponent from './performances/components'
import ProjectsComponent from './projects/components'
import PositionsComponent from './positions/components'
import DisciplinaryActionsComponent from './disciplinaryActions/components'
import AdministratorsComponent from './admins/components'

import PageLayout from '../shared/pageLayout';
import { Layout } from 'antd'
const ClientComponent = () => {

  const [paths, { }] = useState([
    {
      label: <h1 className='label-style'>Dashboard</h1>,
      key: 'Dashboard',
      route: '/',
      exact: "true",
      component: DashboardComponent,
      icon: <FontAwesomeIcon icon={faTachometerAlt} />
    },

    {
      label: 'Activities',
      key: 'activities',
      route: '/activities',
      exact: "false",
      component: Layout,
      icon: <FontAwesomeIcon icon={faStar} />,
      children: [
        {
          label: <h1 className='label-style'>Announcements</h1>,
          key: 'Announcements',
          route: '/announcements',
          exact: "false",
          component: AnnouncementsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        }, {
          label: <h1 className='label-style'>Memorandums</h1>,
          key: 'Memorandums',
          route: '/memorandums',
          exact: "false",
          component: MemorandumsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Projects',
          key: 'projects',
          route: '/projects',
          exact: "false",
          component: ProjectsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Positions',
          key: 'positions',
          route: '/positions',
          exact: "false",
          component: PositionsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
      ]
    },
    {
      label: 'Employees Records',
      key: 'personal-records',
      route: '/personal-records',
      exact: "false",
      component: Layout,
      icon: <FontAwesomeIcon icon={faUsers} />,
      children: [
        {
          label: 'Masterfile',
          key: 'employees',
          route: '/employees',
          exact: "false",
          component: MasterfileComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Employment Histories',
          key: 'employment-histories',
          route: '/employment-histories',
          exact: "false",
          component: EmploymentHistoriesComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Medical Histories',
          key: 'medical-histories',
          route: '/medical-histories',
          exact: "false",
          component: MedicalHistoriesComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Educations',
          key: 'educations',
          route: '/educations',
          exact: "false",
          component: EducationsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'References',
          key: 'references',
          route: '/references',
          exact: "false",
          component: ReferencesComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Dependents',
          key: 'dependents',
          route: '/dependents',
          exact: "false",
          component: DependentsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Documents',
          key: 'documents',
          route: '/documents',
          exact: "false",
          component: DocumentsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        }
      ]
    },
    {
      label: 'Emp Work Related',
      key: 'work-related',
      route: '/work-related',
      exact: "false",
      component: Layout,
      icon: <FontAwesomeIcon icon={faBriefcase} />,
      children: [
        // {
        //   label: 'Allowances',
        //   key: 'allowances',
        //   route: '/allowances',
        //   exact: "false",
        //   component: AllowancesComponent,
        //   icon: <FontAwesomeIcon icon={faAngleRight} />
        // },
        {
          label: 'Timelogs',
          key: 'timelogs',
          route: '/timelogs',
          exact: "false",
          component: TimelogsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Leaves',
          key: 'leaves',
          route: '/leaves',
          exact: "false",
          component: LeavesComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Overtimes',
          key: 'overtimes',
          route: '/overtimes',
          exact: "false",
          component: OvertimesComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Business Trips',
          key: 'business-trips',
          route: '/business-trips',
          exact: "false",
          component: BusinessTripsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Personal Movements',
          key: 'personal-movements',
          route: '/personal-movements',
          exact: "false",
          component: PersonalMovementsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Accountabilities',
          key: 'accountabilities',
          route: '/accountabilities',
          exact: "false",
          component: AccountabilitiesComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'File Reports',
          key: 'file-reports',
          route: '/file-reports',
          exact: "false",
          component: FileReportsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Time Requests',
          key: 'time-requests',
          route: '/time-requests',
          exact: "false",
          component: TimeRequestsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Performances',
          key: 'performances',
          route: '/performances',
          exact: "performances",
          component: PerformancesComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
        {
          label: 'Discilinary Actions',
          key: 'disciplinary-actions',
          route: '/disciplinary-actions',
          exact: "false",
          component: DisciplinaryActionsComponent,
          icon: <FontAwesomeIcon icon={faAngleRight} />
        },
      ]
    },
    {
      label: 'Utilities',
      key: 'utilities',
      route: '/utilities',
      exact: "false",
      component: Layout,
      icon: <FontAwesomeIcon icon={faCogs} />,
      children: [{
        label: 'Administrators',
        key: 'administrators',
        route: '/administrators',
        exact: "false",
        component: AdministratorsComponent,
        icon: <FontAwesomeIcon icon={faAngleRight} />
      },]
    },
  ])


  return (
    <PageLayout title='ADMIN PORTAL' paths={paths} sidebarLoading={false} />
  )
}

export default withRouter(ClientComponent)