import React from 'react'
import { Layout, Menu, Dropdown, Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import session from '@session'
import SiderComponent from '../sider'
import './index.scss'
const { Header, Footer } = Layout;

const PageLayout = (props) => {
    return (
        <Layout className='page-layout'>
            <Header className='header'>
                <h4><Image
                    width={40}
                    src={require('../../../assets/logo/logo.png')}
                /> {props.title}</h4>
                <div>
                    <Dropdown overlay={<Menu items={[
                        { label: <span onClick={() => session.logout()} >Logout</span>, key: 'logout' }
                    ]} />}>
                        <FontAwesomeIcon style={{ color: '#708791', cursor: 'pointer', fontSize: 22 }} icon={faBars} />
                    </Dropdown>
                </div>
            </Header>
            <SiderComponent paths={props.paths} sidebarLoading={props.sidebarLoading} enableRoleValidation={props.enableRoleValidation} />
            <Footer><b style={{ color: 'gray' }}><u>Powered By TWINTOOL BUSINESS SOLUTIONS</u><br /><span style={{ fontSize: 10 }}>All rights reserved @2023</span></b></Footer>
        </Layout>
    )
}

export default PageLayout