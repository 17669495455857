import React from 'react'
import { Layout } from 'antd';
import './index.scss'

const Dashboard = () => {
    return (
        <Layout>
            Dashboard
        </Layout>
    )
}

export default Dashboard
