import React, { Fragment, useEffect, useState } from 'react'
import { Card, Menu, Dropdown, Layout, Popconfirm, Row, Col, notification, List, Pagination, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { load, remove } from '../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import StatusIconDisplay from '@status-icon'
import { ImagePresignedUrl } from '@renderer'
import FormComponent from './form'
import { VIEW, NONE, ADD } from '@page-actions'
import moment from 'moment'

import _ from 'lodash'
import session from '@session'
import Toolbar from '@toolbar'
import './index.scss'

const LeavesComponent = (props) => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const [actionType, setActionType] = useState(NONE)
    const [selectedRow, setSelectedRow] = useState({})
    const [modalShow, setModalShow] = useState(false)
    let [tableData, setTableData] = useState([])

    const dispatch = useDispatch();
    const { getData, getLoading, createSuccess, updateSuccess, removeSuccess } = useSelector(state => state.client.leaves)

    useEffect(() => {
        dispatch(load(pagination))
    }, [pagination])

    useEffect(() => {
        setModalShow(false)
        dispatch(load(pagination))
    }, [updateSuccess, createSuccess, removeSuccess])

    function handleDelete(id) {
        dispatch(remove(id)).then((res) => {
            if (res.type === 'leaves/removeFulfilled') {
                notification.success({
                    message: `Deleted`,
                    description: 'You have successfully deleted data.',
                });
                dispatch(load(pagination))
            }
        })
    }

    function onChangePagination(page, limit) {
        setPagination(prevState => ({ ...prevState, page, limit }));
    }

    useEffect(() => {
        async function initiateSocket() {
            const id = session.user().id
            const type = session.user().type
            // props.socket.on(`leaves.${id}`, data => {
            //     const index = tableData.findIndex(item => item._id === data._id)
            //     if (data.status === 0) tableData.splice(index, 1) // delete
            //     else {
            //         if (index !== -1) tableData[index] = data // update
            //         else {
            //             tableData.unshift({ ...data }) // create
            //             tableData = tableData.slice(0, pagination.limit) // re-assigning and limit
            //         }
            //     }
            //     setTableData([...tableData])
            // });
        }
        initiateSocket()
    }, [tableData])

    useEffect(() => {
        setTableData([...getData.data])
    }, [getData])

    useEffect(() => {
        setModalShow(false)
        dispatch(load(pagination))
    }, [updateSuccess, createSuccess, removeSuccess])

    function handleDelete(id) {
        dispatch(remove(id)).then((res) => {
            if (res.type === 'leaves/removeFulfilled') {
                notification.success({
                    message: `Deleted`,
                    description: 'You have successfully deleted data.',
                });
                dispatch(load(pagination))
            }
        })
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    return (
        <Layout className='leaves-page'>
            <Card bordered={false}>
                <Toolbar
                    title='Leaves'
                    handleSearch={(search) => setPagination({ ...pagination, search })}
                    handleAdd={() => {
                        setModalShow(true)
                        setActionType(ADD)
                        setSelectedRow({})
                    }}
                />
                <Table columns={[
                    {
                        title: 'Type',
                        dataIndex: 'type',
                        key: 'type',
                        render: (item, data) => {
                            return <span>{data.type && data.type.title} <StatusIconDisplay status={data.status} /></span>
                        }
                    },
                    {
                        title: 'Reason',
                        dataIndex: 'reason',
                        key: 'reason',
                        responsive: ['lg', 'md', 'sm'],
                    },
                    {
                        title: 'Date',
                        dataIndex: 'date',
                        key: 'date',
                        render: (item, data) => {
                            return <span>{moment(data.date).format('LL')}</span>
                        }
                    },
                    {
                        title: 'Project',
                        dataIndex: 'project',
                        key: 'project',
                        responsive: ['lg', 'md', 'sm'],
                        render: (item, data) => {
                            return <span>{data.project && data.project.title}</span>
                        }
                    },
                    {
                        title: 'Images / Files',
                        dataIndex: 'images',
                        key: 'images',
                        responsive: ['lg', 'md', 'sm'],
                        render: (item, data) => {
                            return <div className='files'>
                                {
                                    data.images && data.images.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <ImagePresignedUrl src={item.url} style={{ width: 80, height: 100 }} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    },
                    {
                        title: '',
                        dataIndex: 'actions',
                        key: 'actions',
                        render: (item, data) => {
                            return (
                                <div className='actions'>
                                    <Dropdown overlay={<Menu>
                                        <Menu.Item key="0"  >
                                            View
                                        </Menu.Item>
                                        <Menu.Item key="1" >
                                            <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={() => handleDelete(data._id)} okText="Yes" cancelText="No">
                                                Delete
                                            </Popconfirm>
                                        </Menu.Item>
                                    </Menu>}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </Dropdown>
                                </div>
                            )
                        }
                    },
                ]} dataSource={getData.data} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />
                <Row style={{ marginTop: 30, width: '100%' }}>
                    <Col md={24} lg={24} sm={24} xs={24} align="right" >
                        <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                    </Col>
                </Row>
            </Card>
            <FormComponent
                modalShow={modalShow}
                setModalShow={setModalShow}
                setActionType={setActionType}
                actionType={actionType}
                selectedRow={selectedRow}
            />
        </Layout >
    )
}

export default LeavesComponent
