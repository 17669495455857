import React, { useEffect, useState } from 'react'
import { Row, Col, Pagination, Collapse, Card, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { load } from '../actions'
import { IframePresignedUrl } from '@renderer'
import session from '@session'
import _ from 'lodash'
import Toolbar from '@toolbar'
import './index.scss'
const { Panel } = Collapse;
const MemorandumComponents = () => {
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        status: 0,
        sort: 'desc',
        search: '',
    })
    const dispatch = useDispatch();
    const { getData, getLoading } = useSelector(state => state.client.memorandums)

    useEffect(() => {
        dispatch(load(pagination))
        // eslint-disable-next-line
    }, [])

    function onChangePagination(page, limit) {
        const newPagination = {
            page: page,
            limit: limit,
            search: pagination.search
        }
        dispatch(load(newPagination))
        setPagination(prevState => ({
            ...prevState,
            page, limit
        }));
    }

    async function handleRowClick(e, data) {
        const userId = session.user().id
        const submitData = {
            viewerId: userId,
            docId: data._id
        }
        // props.onViewerMemorandum({ ...submitData })
    }

    return (
        <div className='page-content'>
            <Card bordered={false}>
                <Toolbar
                    title='Announcements'
                    handleSearch={(search) => setPagination({ ...pagination, search })}
                />
                <Table columns={[
                    {
                        title: 'Title',
                        dataIndex: 'title',
                        key: 'title'
                    },
                    {
                        title: 'Memorandum Number',
                        dataIndex: 'memoNumber',
                        key: 'memoNumber'
                    },
                    {
                        title: 'Images / Files',
                        dataIndex: 'images',
                        key: 'images',
                        responsive: ['lg', 'md', 'sm'],
                        render: (item, data) => {
                            return <div className='files'>
                                {
                                    data.files && data.files.map((item, index) => {
                                        return (
                                            <Panel header={_.startCase(item.path)} key={index} style={{ backgroundColor: 'white' }}>
                                                <IframePresignedUrl src={item.url} style={{ width: '100%', height: 740 }} /><br />
                                            </Panel>
                                        )
                                    })
                                }
                            </div>
                        }
                    },
                    // {
                    //     title: 'Actions',
                    //     dataIndex: 'actions',
                    //     key: 'actions',
                    //     render: (item, data) => {
                    //         return (
                    //             <div className='actions'>
                    //                 <Dropdown overlay={<Menu items={[
                    //                     {
                    //                         label: 'View', key: 'view', onClick: (e) => { handleRowClick(e, data) }
                    //                     }
                    //                 ]} />}>
                    //                     <FontAwesomeIcon icon={faEllipsisH} />
                    //                 </Dropdown>
                    //             </div>
                    //         )
                    //     }
                    // },
                ]} dataSource={getData.data} rowKey="_id" pagination={false} style={{ overflowX: 'scroll' }} loading={getLoading} />

            </Card>
            <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right" >
                    <Pagination onChange={(page, limit) => onChangePagination(page, limit)} total={getData.total} />
                </Col>
            </Row>
        </div >
    )
}

export default MemorandumComponents