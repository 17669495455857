import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, Select, DatePicker, Checkbox, Modal, notification } from 'antd'
import { update, create, loadTypes } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import session from '@session'
import { upload } from '../../../../utilities/filesController'
import './index.scss'
import moment from 'moment'
import { CustomDropzoneMultiple } from '../../../../utilities/customDropzone'
import { ImagePresignedUrl } from '@renderer'
import { load as loadProjects } from '../../../admin/projects/actions'
const { Option } = Select
const FormLeave = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])
    const [listOfImageObject, setListOfImageObject] = useState([])
    const dispatch = useDispatch();
    const { updateLoading, getTypesData } = useSelector(state => state.client.leaves)
    const projects = useSelector(state => state.admin.projects)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            dateFrom: (props.selectedRow && moment(props.selectedRow.dateFrom)) || new Date(),
            dateTo: (props.selectedRow && moment(props.selectedRow.dateTo)) || new Date(),
        })
        props.selectedRow && props.selectedRow.images && setImages(props.selectedRow.images)

        dispatch(loadProjects({
            page: 1,
            limit: 100,
        }))

        dispatch(loadTypes({
            page: 1,
            limit: 100,
        }))
        // eslint-disable-next-line
    }, [props.selectedRow])

    function getFileImage(arrayImageObj) {
        setListOfImageObject(arrayImageObj)
    }

    async function handleSubmit(value) {
        setLoading(true)

        const submitData = {
            ...value,
        }

        if (props.actionType === ADD) {
            const userId = session.user().id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(create({ userId, images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        if (res.type === 'leaves/createFulfilled') {
                            props.setActionType(NONE)
                            setLoading(false)
                            form.resetFields()
                            notification.success({
                                message: `Added`,
                                description: 'You have successfully added information.',
                            });
                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }

        if (props.actionType === VIEW) {
            const leaveId = props.selectedRow._id
            Promise.all([
                uploadFileImage(),
            ]).then((res) => {
                const isAllResultTrue = res.every(data => data === true)
                if (isAllResultTrue) {
                    dispatch(update({ id: leaveId, images: [...images, ...listOfImageObject], ...submitData })).then(res => {
                        if (res.type === 'leaves/updateFulfilled') {
                            setLoading(false)
                            form.resetFields()
                            notification.success({
                                message: `Updated`,
                                description: 'You have successfully updated information.',
                            });
                        }
                    })
                } else {
                    notification.error({
                        message: `Failed to update data`,
                    });
                    setLoading(false)
                }
            })
        }
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (listOfImageObject.length) {
                listOfImageObject.map(item => {
                    upload(item, item.name, item.uploadDir).then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return notification.error({
                            message: `Failed to upload image`,
                            description: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function handleRemoveSelectedImage(id) {
        const fiteredImage = images.filter(image => image.id !== id)
        setImages(fiteredImage)
    }

    // do not allowed to edit  'Date from' and 'Date To' when already approved because its been deducted already to the selected user.
    const isDeducted = (props.selectedRow && props.selectedRow.isDeducted)

    return (
        <Modal forceRender title={(props.selectedRow && props.selectedRow.reason) || 'Add Leave'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`leave-${props.selectedId}`}
                    form={form}

                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Reason For Taking Leave"
                                name="reason"
                                rules={[{ required: true, message: 'Please input reason!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Date From"
                                name="dateFrom"
                                rules={[{ required: true, message: 'Please input date from!' }]}
                            >
                                <DatePicker
                                    disabled={isDeducted}
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Type"
                                name="typeId"
                                rules={[{ required: true, message: 'Please input type!' }]}
                            >
                                <Select disabled={isDeducted}  >
                                    {
                                        getTypesData.data.map((item, index) => {
                                            return (
                                                <Option key={index} value={item._id}>{item.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Date To"
                                name="dateTo"
                                rules={[{ required: true, message: 'Please input date to!' }]}
                            >
                                <DatePicker
                                    disabled={isDeducted}
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Projects"
                                name="projectId"
                                rules={[{ required: true, message: 'Please input project id!' }]}
                            >
                                <Select>
                                    {
                                        projects.getData.data.map((data, index) => {
                                            return (
                                                <Option value={data._id} key={index}>{data.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Form.Item
                                name="isWithPay"
                                valuePropName="checked"
                            >
                                <Checkbox >Leave with Pay?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <CustomDropzoneMultiple
                                fileName='leaves'
                                uploadDir='leaves'
                                height={200}
                                width={200}
                                getFile={file => { getFileImage(file) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className='d-flex'>
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div key={index} >
                                                <ImagePresignedUrl src={item.url} style={{ width: 200, height: 150 }} />
                                                <span className='red pointer' onClick={() => handleRemoveSelectedImage(item.id)}>Delete</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={updateLoading || loading}   >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

export default FormLeave