import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Input, Button, Layout, Form, DatePicker, Modal, notification } from 'antd'
import { update, create } from '../actions'
import { ADD, VIEW, NONE } from '@page-actions'
import session from '@session'
import './index.scss'
import moment from 'moment'
const FormTimeRequest = (props) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const { updateLoading } = useSelector(state => state.client.timeRequests)

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...props.selectedRow,
            timeIn: props.selectedRow && moment(props.selectedRow.timeIn) || new Date(),
            timeOut: props.selectedRow && moment(props.selectedRow.timeOut) || new Date(),
        })
        // eslint-disable-next-line
    }, [props.selectedRow])

    async function handleSubmit(value) {

        const submitData = { ...value, }

        if (props.actionType === ADD) {
            const userId = session.user().id
            dispatch(create({ userId, ...submitData })).then(res => {
                if (res.type === 'timeRequests/createFulfilled') {
                    props.setActionType(NONE)
                    form.resetFields()
                    notification.success({
                        message: `Added`,
                        description: 'You have successfully added information.',
                    });
                }
            })
        }

        if (props.actionType === VIEW) {
            const timeRequestId = props.selectedRow._id
            dispatch(update({ id: timeRequestId, ...submitData })).then(res => {
                if (res.type === 'timeRequests/updateFulfilled') {
                    form.resetFields()
                    notification.success({
                        message: `Updated`,
                        description: 'You have successfully updated information.',
                    });
                }
            })
        }
    }

    return (
        <Modal forceRender title={props.selectedRow && props.selectedRow.company || 'Add Time Request'} visible={props.modalShow} footer={null} onCancel={() => props.setModalShow(false)} width={800}>
            <Layout style={{ backgroundColor: 'white' }}>
                <Form
                    name={`timeRequest`}
                    form={form}
                    onFinish={(e) => handleSubmit(e)}
                >
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please input title!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>

                        <Col md={12} lg={12} sm={24} xs={24}>
                            <Form.Item
                                label="Time In"
                                name="timeIn"
                                rules={[{ required: true, message: 'Please input time in!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Time Out"
                                name="timeOut"
                                rules={[{ required: true, message: 'Please input time out!' }]}
                            >
                                <DatePicker
                                    className='primary-input'
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} lg={24} sm={24} align={'right'}>
                            <Form.Item >
                                <Button className='primary-btn' type="primary" htmlType="submit"
                                    loading={updateLoading}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

export default FormTimeRequest