import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const load = (pagination) => {
    const query = queryString.stringify(pagination)
    return dispatch => {
        dispatch(actions.get())
        return Axios.get(`${API_URL}/overtimes?${query}`, { headers: headers })
            .then(function (response) {
                return dispatch(actions.getFulfilled(response.data))
            }).catch(function (error) {
                return dispatch(actions.getRejected(error))
            })
    }
}

export const create = (data) => {
    return dispatch => {
        dispatch(actions.create())
        return Axios.post(`${API_URL}/overtime`, { ...data }, { headers: headers })
            .then(function (response) {
                return dispatch(actions.createFulfilled(response.data))
            }).catch(function (error) {
                return dispatch(actions.createRejected(error))
            })
    }
}

export const update = (data) => {
    return dispatch => {
        dispatch(actions.update())
        return Axios.put(`${API_URL}/overtime/${data.id}`, { ...data }, { headers: headers })
            .then(function (response) {
                return dispatch(actions.updateFulfilled(response.data))
            }).catch(function (error) {
                return dispatch(actions.updateRejected(error))
            })
    }
}

export const remove = (id) => {
    return dispatch => {
        dispatch(actions.remove())
        return Axios.delete(`${API_URL}/overtime/${id}`, { headers: headers })
            .then(function (response) {
                return dispatch(actions.removeFulfilled(response.data))
            }).catch(function (error) {
                return dispatch(actions.removeRejected(error))
            })
    }
}
