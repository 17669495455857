import React, { useState, useEffect } from 'react'
import { Row, Col, Tooltip, Button, Input, Dropdown, Menu, DatePicker } from 'antd'
import { PlusOutlined, DownloadOutlined, DownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './index.scss'
const { RangePicker } = DatePicker;
const Toolbar = (props) => {
    const [filter, { }] = useState(props.statusArray || [])
    const [selectedFilter, setSelectedFilter] = useState(filter[0])
    const [sort, setSort] = useState('desc')
    const [gender, setGender] = useState(0)
    const [tenure, setTenure] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [dateRange, setDateRange] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            props.handleSearch && props.handleSearch(searchTerm)
        }, 1300)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    function onAdd() {
        props && props.handleAdd()
    }

    function onBack() {
        props && props.handleBack()
    }

    function onSort(value) {
        props && props.handleSort(value)
        setSort(value)
    }

    function onGender(value) {
        props && props.handleGender(value)
        setGender(value)
    }

    function genderLabel(value) {
        switch (value) {
            case 0:
                return 'All'
            case 1:
                return 'Male'
            case 2:
                return 'Female'
            default:
                break;
        }
    }

    function onTenure(value) {
        props && props.handleTenure(value)
        setTenure(value)
    }

    function onChangeRangeDate(value) {
        props && props.handleDateRange(value)
        setDateRange(value)
    }
    return (
        <div className='toolbar-container'>
            <Row style={{ width: '100%' }}>
                <Col md={12} lg={12} xs={24} sm={24}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            (props && (props && props.handleBack)) &&
                            <Tooltip title="Back"><Button className='btn-circle' shape="circle" icon={<ArrowLeftOutlined />} onClick={() => onBack()} /></Tooltip>
                        }
                        <h1>{props.title}</h1>
                    </div>
                    {
                        (props && (props.handleAdd && !props.removeAdd)) &&
                        <Tooltip title="Add"><Button className='btn-circle' shape="circle" icon={<PlusOutlined />} onClick={() => onAdd()} /></Tooltip>
                    }
                    {
                        props.handleDownload &&
                        <Tooltip title="Download CSV"><Button onClick={() => props.handleDownload()} className='btn-circle' shape="circle" icon={<DownloadOutlined />} /></Tooltip>
                    }
                    {
                        props && props.customAdd && props.customAdd.map((data, index) => {
                            const { component } = data
                            return (
                                <div style={{ display: 'inline', width: '100%' }} key={index}>
                                    {component}
                                </div>
                            )
                        })
                    }
                </Col>
                <Col md={12} lg={12} xs={24} sm={24} align='right'>
                    <Row>
                        {
                            (props && props.handleDateRange) &&
                            <Col md={24} lg={24} xs={24} sm={24}>
                                <RangePicker showTime style={{ padding: '12px 11px 4px 20px', borderRadius: 8 }}
                                    value={dateRange}
                                    onChange={(e) => onChangeRangeDate(e)}
                                />
                            </Col>
                        }
                    </Row>
                    <Row>
                        {
                            (props && props.handleSearch) &&
                            <Col md={24} lg={24} xs={24} sm={24}>
                                <Input className='search' placeholder="Search Something..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} allowClear size="large" />
                            </Col>
                        }
                    </Row>
                    <Row gutter={8}  >
                        <div style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}>
                            {
                                (props && props.handleStatus) &&
                                <Dropdown className='dropdown-filter' overlay={(
                                    <Menu items={
                                        filter.map((data, index) => {
                                            return {
                                                label: data.label, key: index, onClick: () => {
                                                    setSelectedFilter(data)
                                                    props.handleStatus(data.id)
                                                }
                                            }
                                        })
                                    } />
                                )} placement="bottom">
                                    <Button>
                                        <span className='fade'>Status </span> {selectedFilter && selectedFilter.label}<b><DownOutlined /></b>
                                    </Button>
                                </Dropdown>
                            }
                            {
                                props && props.customFilter && props.customFilter.map((data, index) => {
                                    const { component } = data
                                    return (
                                        component
                                    )
                                })
                            }
                            {
                                (props && props.handleSort) &&
                                <Dropdown className='dropdown-filter' overlay={(
                                    <Menu items={[
                                        { label: 'DESC', key: 'desc', onClick: () => onSort('desc') },
                                        { label: 'ASC', key: 'asc', onClick: () => onSort('asc') },
                                    ]} />
                                )} placement="bottom">
                                    <Button>
                                        <span className='fade'>Sort </span><span className='label'>{sort.toUpperCase()}</span><b><DownOutlined /></b>
                                    </Button>
                                </Dropdown>
                            }
                            {
                                (props && props.handleGender) &&
                                <Dropdown className='dropdown-filter' overlay={(
                                    <Menu items={[
                                        { label: 'All', key: 0, onClick: () => onGender(0) },
                                        { label: 'Male', key: 1, onClick: () => onGender(1) },
                                        { label: 'Female', key: 2, onClick: () => onGender(2) },
                                    ]} />
                                )} placement="bottom">
                                    <Button>
                                        <span className='fade'>Gender </span><span className='label'>{genderLabel(gender)}</span><b><DownOutlined /></b>
                                    </Button>
                                </Dropdown>
                            }
                            {
                                (props && props.handleTenure) &&
                                <Dropdown className='dropdown-filter' overlay={(
                                    <Menu>
                                        <Menu.Item onClick={() => { onTenure(0) }} >All</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(5) }} >5 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(10) }} >10 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(15) }} >15 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(25) }} >25 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(30) }} >30 years</Menu.Item>
                                        <Menu.Item onClick={() => { onTenure(35) }} >35 years</Menu.Item>
                                    </Menu>
                                )} placement="bottom">
                                    <Button>
                                        <span className='fade'>Tenure </span><span className='label'>{tenure === 0 ? 'All' : tenure} Years</span><b><DownOutlined /></b>
                                    </Button>
                                </Dropdown>
                            }
                        </div>
                    </Row>
                </Col>
            </Row >
        </div >
    )
}

export default Toolbar