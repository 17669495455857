
import { createSlice } from "@reduxjs/toolkit";

const employees = createSlice({
  name: 'employees',
  initialState: {
    getByIdLoading: false,
    getByIdSuccess: false,
    getByIdRejected: false,
    getByIdData: {},
    updateLoading: false,
    updateSuccess: false,
    updateRejected: false,
  },
  reducers: {
    getById(state, action) {
      return {
        ...state,
        getByIdLoading: true,
        getByIdSuccess: false,
        getByIdRejected: false
      }
    },
    getByIdFulfilled(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdSuccess: true,
        getByIdData: action.payload
      }
    },
    getByIdRejected(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdRejected: true
      }
    },
    putChangePassword(state, action) {
      return {
        ...state,
        putChangePasswordLoading: true,
        putChangePasswordSuccess: false,
        putChangePasswordRejected: false
      }
    },
    putChangePasswordFulfilled(state, action) {
      return {
        ...state,
        putChangePasswordLoading: false,
        putChangePasswordSuccess: true,
        putChangePasswordData: action.payload
      }
    },
    putChangePasswordRejected(state, action) {
      return {
        ...state,
        putChangePasswordLoading: false,
        putChangePasswordRejected: true
      }
    },
    update(state, action) {
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        updateRejected: false
      }
    },
    updateFulfilled(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
        updateData: action.payload
      }
    },
    updateRejected(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateRejected: true
      }
    },

  }
});

export const actions = employees.actions;

export default employees.reducer;