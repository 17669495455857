import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col, Card, Select, Alert } from 'antd';
import './index.scss'
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    BarElement
} from 'chart.js';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import { load as loadEmployees } from '../../employee/actions';
import { loadReports } from '../actions';
const { Option } = Select
ChartJS.register(ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend);

const totalEmployessData = [2530, 3068]

export const employeesData = {
    labels: ['Male', 'Female'],
    datasets: [
        {
            data: totalEmployessData,
            backgroundColor: [
                'rgba(0, 122, 251,0.5)',
                'rgba(251, 0, 0,0.5)',
            ],
            borderColor: [
                '#007AFB',
                'red',
            ],
            borderWidth: 1,
        },
    ],
};

export const projectsData = {
    labels: ['January', 'February'],
    datasets: [
        {
            label: 'Dataset 1',
            data: [10],
            borderColor: 'rgb(143, 255, 87)',
            backgroundColor: 'rgba(143, 255, 87, 0.5)',
        },
        {
            label: 'Dataset 1',
            data: [20],
            borderColor: 'rgb(237, 12, 42)',
            backgroundColor: 'rgba(237, 12, 42)',
        },
    ],
};

const Dashboard = (props) => {
    const [search, setSearch] = useState('')
    const dispatch = useDispatch();
    const { getReportsData } = useSelector(state => state.admin.dashboard)

    // useEffect(() => {
    //     if (!search.length) return
    //     const timerId = setTimeout(() => {
    //         props.onLoadEmployees({
    //             page: 1,
    //             limit: 50,
    //             search: search,
    //         })
    //     }, 1000);
    //     return () => {
    //         clearTimeout(timerId);
    //     };
    // }, [search]);

    useEffect(() => {
        dispatch(loadReports())
    }, [])

    function handleSearchEmployee(id) {
        props.history.push(`/admin/employee?empId=${id}&view=true`)
    }
    console.log("getReportsData", getReportsData)
    const { leaves, businessTrips, overtimes, timeRequests, newlyHired, newlyResigned, employees } = getReportsData
    return (
        <Layout className='dashboard-container' style={{ minHeight: '100vh', padding: 24 }}>
            {/* <Row>
                <Col md={24} lg={24} sm={24} xs={24}>
                    <Card style={{ background: '#708791', borderRadius: 12 }}>
                        <h1 style={{ fontSize: 28, fontWeight: 'bold', color: 'white', }}>Search</h1>
                        <Select placeholder='Search Employee Name' style={{ width: '100%' }} showSearch onSearch={(value) => setSearch(value)} onChange={(id) => handleSearchEmployee(id)} optionFilterProp="children"
                            filterOption={(input, option) => {
                                return (
                                    (option.children.join('').toLowerCase().includes(input.toLowerCase())) ||
                                    (option.props.value && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
                                    (option.props.empid && option.props.empid.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                                )
                            }

                            }
                            loading={props.employeesLoading}>
                            {
                                props.employeesData.data.map((data, index) => {
                                    console.log("data", data.firstName)
                                    return (
                                        <Option key={data._id} value={data._id} empid={data.employeeNumber} >{data.firstName} {data.lastName} <span style={{ fontStyle: 'italic' }}>({data.employeeNumber})</span></Option>
                                    )
                                })
                            }
                        </Select>
                    </Card>
                </Col>
            </Row><br /> */}

            <Row gutter={16}>
                <Col md={6} lg={6} sm={24} xs={24}>
                    <Card className='panel-card'>
                        <div className='head-count'>
                            <h1>Leaves</h1>
                            <span>{leaves ? leaves.total : 0}</span>
                        </div>
                        <div className='status-count'>
                            <div className='status-value'>
                                <p>Approval</p>
                                <p>{leaves ? leaves.approval : 0}</p>
                            </div>
                            <div className='status-value'>
                                <p>Approved</p>
                                <p>{leaves ? leaves.approved : 0}</p>
                            </div>
                            <div className='status-value'>
                                <p>Rejected</p>
                                <p>{leaves ? leaves.rejected : 0}</p>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col md={6} lg={6} sm={24} xs={24}>
                    <Card className='panel-card'>
                        <div className='head-count'>
                            <h1>Business Trips</h1>
                            <span>{businessTrips ? businessTrips.total : 0}</span>
                        </div>
                        <div className='status-count'>
                            <div className='status-value'>
                                <p>Approval</p>
                                <p>{businessTrips ? businessTrips.approval : 0}</p>
                            </div>
                            <div className='status-value'>
                                <p>Approved</p>
                                <p>{businessTrips ? businessTrips.approved : 0}</p>
                            </div>
                            <div className='status-value'>
                                <p>Rejected</p>
                                <p>{businessTrips ? businessTrips.rejected : 0}</p>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col md={6} lg={6} sm={24} xs={24}>
                    <Card className='panel-card'>
                        <div className='head-count'>
                            <h1>Overtimes</h1>
                            <span>{overtimes ? overtimes.total : 0}</span>
                        </div>
                        <div className='status-count'>
                            <div className='status-value'>
                                <p>Approval</p>
                                <p>{overtimes ? overtimes.approval : 0}</p>
                            </div>
                            <div className='status-value'>
                                <p>Approved</p>
                                <p>{overtimes ? overtimes.approved : 0}</p>
                            </div>
                            <div className='status-value'>
                                <p>Rejected</p>
                                <p>{overtimes ? overtimes.rejected : 0}</p>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col md={6} lg={6} sm={24} xs={24}>
                    <Card className='panel-card'>
                        <div className='head-count'>
                            <h1>Time Requests</h1>
                            <span>{timeRequests ? timeRequests.total : 0}</span>
                        </div>
                        <div className='status-count'>
                            <div className='status-value'>
                                <p>Approval</p>
                                <p>{timeRequests ? timeRequests.approval : 0}</p>
                            </div>
                            <div className='status-value'>
                                <p>Approved</p>
                                <p>{timeRequests ? timeRequests.approved : 0}</p>
                            </div>
                            <div className='status-value'>
                                <p>Rejected</p>
                                <p>{timeRequests ? timeRequests.rejected : 0}</p>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 24 }}>
                <Col md={16} lg={16} sm={24} xs={24}>
                    <Alert
                        message="Employees Movement"
                        description="The Bar Graph shown below are the movement indicator of the employees. Newly Hired/Resigned (1 MONTH)"
                        type="info"
                    />
                    <Bar options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Employees Movement',
                            },
                        },
                    }} data={{
                        labels: ['Newly Hired', 'Newly Resigned',],
                        datasets: [{
                            label: 'Count',
                            data: [newlyHired || 0, newlyResigned || 0],
                            backgroundColor: [
                                'rgba(68, 255, 0, 0.2)',
                                'rgba(237, 12, 42, 0.2)',
                            ],
                            borderColor: [
                                'rgb(68, 255, 0)',
                                'rgb(237, 12, 42)',
                            ],
                            borderWidth: 0
                        }]
                    }} />;

                </Col>
                <Col md={8} lg={8} sm={24} xs={24}>
                    <Alert
                        message="Employees Movement"
                        description="Visualization Pie chart shows the count of Male/Female inside the company."
                        type="info"
                    />
                    <Doughnut data={{
                        labels: ['Male', 'Female'],
                        datasets: [{
                            label: 'Employees',
                            data: [employees ? employees.male : 0, employees ? employees.female : 0],
                            backgroundColor: [
                                'rgba(137, 179, 224, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgb(137, 179, 224)',
                                'rgb(255, 159, 64)',
                            ],
                            borderWidth: 1
                        }]
                    }} />
                </Col>
            </Row>
        </Layout >
    )
}


export default Dashboard