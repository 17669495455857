import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';
const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const load = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch(actions.get())
    return Axios.get(`${API_URL}/leaves?${query}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getRejected(error))
      })
  }
}

export const loadById = (id) => {
  return dispatch => {
    dispatch(actions.getById())
    return Axios.get(`${API_URL}/leave/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getByIdFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getByIdRejected(error))
      })
  }
}

export const create = (data) => {
  return dispatch => {
    dispatch(actions.create())
    return Axios.post(`${API_URL}/leave`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.createFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.createRejected(error))
      })
  }
}

export const update = (data) => {
  return dispatch => {
    dispatch(actions.update())
    return Axios.put(`${API_URL}/leave/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.updateFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.updateRejected(error))
      })
  }
}

export const remove = (id) => {
  return dispatch => {
    dispatch(actions.remove())
    return Axios.delete(`${API_URL}/leave/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.removeFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.removeRejected(error))
      })
  }
}

export const loadType = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch(actions.getType())
    return Axios.get(`${API_URL}/leave-types?${query}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getTypeFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getTypeRejected(error))
      })
  }
}

export const loadTypeById = (id) => {
  return dispatch => {
    dispatch(actions.getTypeById())
    return Axios.get(`${API_URL}/leave-type/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getTypeByIdFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getTypeByIdRejected(error))
      })
  }
}

export const createType = (data) => {
  return dispatch => {
    dispatch(actions.createType())
    return Axios.post(`${API_URL}/leave-type`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.createTypeFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.createTypeRejected(error))
      })
  }
}

export const updateType = (data) => {
  return dispatch => {
    dispatch(actions.updateType())
    return Axios.put(`${API_URL}/leave-type/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.updateTypeFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.updateTypeRejected(error))
      })
  }
}

export const removeType = (id) => {
  return dispatch => {
    dispatch(actions.removeType())
    return Axios.delete(`${API_URL}/leave-type/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.removeTypeFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.removeTypeRejected(error))
      })
  }
}
