import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Switch, Tabs, Divider, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { update } from '../../actions'
import _ from 'lodash';
import '../index.scss'
import { defaultRoles } from '../.././../../../utilities/roles'

const { TabPane } = Tabs;
const Roles = (props) => {
  const [roles, setRoles] = useState([])
  const dispatch = useDispatch();

  function mergeDefaultAndExistingRoles() {
    let newDefaultRoles = []
    defaultRoles.map((defRoles, index) => {
      const found = props.selectedRow && props.selectedRow.roles.find(role => (role.title) === defRoles.title)
      if (found) newDefaultRoles.push(found)
      else newDefaultRoles.push(defRoles)
    })
    return newDefaultRoles
  }

  useEffect(() => {
    const roles = props.selectedRow && props.selectedRow.roles.length === 0 ? defaultRoles : mergeDefaultAndExistingRoles()
    setRoles(roles)
  }, [props.selectedRow])

  function handleChangeRoles(value, title, roleKey) {
    setRoles(state => {
      return state.map(item => {
        if (item.title === title) {
          let roles = { ...item.roles, [roleKey]: value }
          return { ...item, roles }
        } else {
          return item
        }
      })
    });
  }

  function handlePageEnable(value, title) {
    setRoles(state => {
      return state.map(item => {
        return item.title === title ? { ...item, visible: value } : item
      })
    });
  }

  function handleSubmitRoles() {
    const submitData = {
      id: props.selectedRow._id,
      roles: roles
    }
    dispatch(update(submitData)).then(res => {
      if (res.type === 'positions/updateFulfilled') {
        notification.success({ message: 'Updated', description: 'You have successfully updated a data.' });
        props.onCancel()
        props.onSuccess && props.onSuccess()
        setRoles([])
      }
    })
  }

  function handleCancel() {
    props.onCancel()
    setRoles([])
  }

  return (
    <Modal
      title="Roles Management"
      className="roles-management-modal"
      centered
      visible={props.visible}
      // onOk={() => setVisible(false)}
      onCancel={() => handleCancel()}
      width={1000}
      footer={null}
    >
      <Tabs tabPosition='left' centered={true}>
        {
          roles.map((data, index) => {
            return (
              <TabPane tab={_.startCase(data.title)} key={index}>
                <h1>Page Settings <span>{`(${_.startCase(data.title)})`}</span></h1>
                <h4>Switching this to 'ON' that means you allow this POSITION to view the {_.startCase(data.title)} Page.</h4>
                <Switch defaultChecked={data.visible} onChange={(value) => handlePageEnable(value, data.title)} />
                <Divider style={{ backgroundColor: '#86bdf7' }} />
                <h1>Access Roles</h1>
                <Row align="bottom">
                  {
                    Object.keys(data.roles).map(function (key, index) {
                      return (
                        <Col span={4} style={{ marginBottom: 100 }} key={index}>
                          <h4>{_.startCase(key)}</h4>
                          <Switch defaultChecked={data.roles[key]} onChange={(value) => handleChangeRoles(value, data.title, key)} />
                        </Col>

                      )
                    })
                  }
                </Row>
              </TabPane>
            )
          })
        }
      </Tabs>
      <Row>
        <Col md={24} lg={24} sm={24} align={'right'}>
          <Button type="primary" onClick={() => handleSubmitRoles()} >
            Update Roles
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default Roles


