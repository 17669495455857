import Axios from 'axios'
import { headers } from '../../../../utilities/token'
import { actions } from '../reducers';

const API_URL = process.env.REACT_APP_API_BASE_URL
const queryString = require('query-string');

export const load = (pagination) => {
  const query = queryString.stringify(pagination)
  return dispatch => {
    dispatch(actions.getMemorandums())
    return Axios.get(`${API_URL}/memorandums?${query}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getMemorandumsFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getMemorandumsRejected(error))
      })
  }
}

export const loadMemorandumsAll = () => {
  return dispatch => {
    dispatch(actions.getMemorandumsAll())
    return Axios.get(`${API_URL}/memorandums-all`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getMemorandumsAllFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getMemorandumsAllRejected(error))
      })
  }
}

export const loadById = (id) => {
  return dispatch => {
    dispatch(actions.getMemorandumById())
    return Axios.get(`${API_URL}/memorandum/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.getMemorandumByIdFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.getMemorandumByIdRejected(error))
      })
  }
}

export const create = (data) => {
  return dispatch => {
    dispatch(actions.createMemorandum())
    return Axios.post(`${API_URL}/memorandum`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.createMemorandumFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.createMemorandumRejected(error))
      })
  }
}

export const update = (data) => {
  return dispatch => {
    dispatch(actions.updateMemorandum())
    return Axios.put(`${API_URL}/memorandum/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch(actions.updateMemorandumFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.updateMemorandumRejected(error))
      })
  }
}

export const remove = (id) => {
  return dispatch => {
    dispatch(actions.removeMemorandum())
    return Axios.delete(`${API_URL}/memorandum/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch(actions.removeMemorandumFulfilled(response.data))
      }).catch(function (error) {
        return dispatch(actions.removeMemorandumRejected(error))
      })
  }
}

