import { createSlice } from "@reduxjs/toolkit";

const memorandums = createSlice({
  name: 'memorandums',
  initialState: {
    getLoading: false,
    getSuccess: false,
    getRejected: false,
    getData: {
      data: []
    },
    getByIdLoading: false,
    getByIdSuccess: false,
    getByIdRejected: false,
    getByIdData: {},
    updateLoading: false,
    updateSuccess: false,
    updateRejected: false,
    createLoading: false,
    createSuccess: false,
    createRejected: false,
    removeLoading: false,
    removeSuccess: false,
    removeRejected: false,
    getAllLoading: false,
    getAllSuccess: false,
    getAllRejected: false,
    getAllData: []
  },
  reducers: {
    getMemorandums(state, action) {
      return {
        ...state,
        getLoading: true,
        getSuccess: false,
        getRejected: false
      }
    },
    getMemorandumsFulfilled(state, action) {
      return {
        ...state,
        getLoading: false,
        getSuccess: true,
        getData: action.payload
      }
    },
    getMemorandumsRejected(state, action) {
      return {
        ...state,
        getLoading: false,
        getRejected: true
      }
    },
    getMemorandumsAll(state, action) {
      return {
        ...state,
        getAllLoading: true,
        getAllSuccess: false,
        getAllRejected: false
      }
    },
    getMemorandumsAllFulfilled(state, action) {
      return {
        ...state,
        getAllLoading: false,
        getAllSuccess: true,
        getAllData: action.payload
      }
    },
    getMemorandumsAllRejected(state, action) {
      return {
        ...state,
        getAllLoading: false,
        getAllRejected: true
      }
    },

    getMemorandumInventoriesCount(state, action) {
      return {
        ...state,
        memorandumInventoriesCountLoading: true,
        memorandumInventoriesCountSuccess: false,
        memorandumInventoriesCountRejected: false
      }
    },
    getMemorandumInventoriesCountFulfilled(state, action) {
      return {
        ...state,
        memorandumInventoriesCountLoading: false,
        memorandumInventoriesCountSuccess: true,
        memorandumInventoriesCountData: action.payload
      }
    },
    getMemorandumInventoriesCountRejected(state, action) {
      return {
        ...state,
        memorandumInventoriesCountLoading: false,
        memorandumInventoriesCountRejected: true
      }
    },

    getMemorandumById(state, action) {
      return {
        ...state,
        getByIdLoading: true,
        getByIdSuccess: false,
        getByIdRejected: false
      }
    },
    getMemorandumByIdFulfilled(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdSuccess: true,
        getByIdData: action.payload
      }
    },
    getMemorandumByIdRejected(state, action) {
      return {
        ...state,
        getByIdLoading: false,
        getByIdRejected: true
      }
    },
    createMemorandum(state, action) {
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createRejected: false
      }
    },
    createMemorandumFulfilled(state, action) {
      return {
        ...state,
        createLoading: false,
        createSuccess: true,
        createData: action.payload
      }
    },
    createMemorandumRejected(state, action) {
      return {
        ...state,
        createLoading: false,
        createRejected: true
      }
    },
    updateMemorandum(state, action) {
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        updateRejected: false
      }
    },
    updateMemorandumFulfilled(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
        updateData: action.payload
      }
    },
    updateMemorandumRejected(state, action) {
      return {
        ...state,
        updateLoading: false,
        updateRejected: true
      }
    },
    removeMemorandum(state, action) {
      return {
        ...state,
        removeLoading: true,
        removeSuccess: false,
        removeRejected: false
      }
    },
    removeMemorandumFulfilled(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeSuccess: true,
        removeData: action.payload
      }
    },
    removeMemorandumRejected(state, action) {
      return {
        ...state,
        removeLoading: false,
        removeRejected: true
      }
    }
  }
});

export const actions = memorandums.actions;

export default memorandums.reducer;